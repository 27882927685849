import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import { Route, Routes } from "react-router";
import { BrowserRouter } from "react-router-dom";
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
// import './App.css';
import CandidateInfo from "./Pages/CandidateInfo";
import SearchResult from "./Pages/SearchResult";
import ThankYou from "./Pages/ThankYou";
import VoterForm from "./Pages/VoterForm";
import VoterList from "./Pages/VoterList";
import Dashboard from "./Pages/Dashboard";
import Login from "./Pages/Login";
import Bio from "./Pages/Bio";
import CandidateDashbord from "./Pages/CandidateDashbord";
import PollingBooth from "./Pages/PollingBooth";
import DisplayVoterList from "./Pages/DisplayVoterList";
import VotersByAge from "./Pages/VotersByAge";
import AreaWiseList from "./Pages/AreaWiseList";
import VoterByCast from "./Pages/VoterByCast";
import VisitedVoters from "./Pages/VisitedVoters";
import "primereact/resources/primereact.min.css";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import "primeicons/primeicons.css";
import FieldWorkerList from "./Pages/FieldWorkerList";
import MarriedFemaleVoters from "./Pages/MarriedFemaleVoters";
import VotersBySurname from "./Pages/VotersBySurname";
import DeadVoters from "./Pages/DeadVoters";
import AddressChangedVoters from "./Pages/AddressChangedVoters";
import PhoneNumberList from "./Pages/PhoneNumberList";
import FamilyAddedList from "./Pages/FamilyAddedList";
import ConfirmedVotersList from "./Pages/ConfirmedVotersList";
import PollingAreaPLacesList from "./Pages/PollingAreaPLacesList";
import BirthDateList from "./Pages/BirthDateList";
import BirthdayList from "./Pages/BirthdayList";
import "../src/Assets/Css/style.css";
import PollingAgent from "./Pages/PollingAgent";
import CandidateConfig from "./Pages/CandidateConfig";
import { AuthProvider } from "./Auth/AuthContext";
import PrivateRoute from "./Auth/PrivateRoute";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import GraphForCount from "./Pages/GraphForCount";
import CandidateLogin from "./Pages/candidateLogin";

function App() {
  return (
    <AuthProvider>
      <BrowserRouter>
        <Routes>
          {/* Public Route */}
          <Route path="/" element={<VoterForm />} />
          <Route path="/candidate" element={<VoterForm />} />
          <Route path="/searchresult" element={<SearchResult />} />
          <Route path="/voterList" element={<VoterList />} />
          <Route path="/thankupage" element={<ThankYou />} />
          <Route path="/candidateinfo" element={<CandidateInfo />} />
          <Route path="/login" element={<Login />} />
          <Route path="/candidateLogin" element={<CandidateLogin />} />
          <Route path="/bio" element={<Bio />} />

          {/* Private Route */}
          <Route path="/candidatedashboard" element={<PrivateRoute element={<CandidateDashbord />} allowedRoutes={["/displayvoterlist"]} />} />
          <Route path="/pollingbooth" element={<PrivateRoute element={<PollingBooth />} allowedRoutes={["/displayvoterlist"]} />} />
          <Route path="/displayvoterlist" element={<PrivateRoute element={<DisplayVoterList />} allowedRoutes={["/displayvoterlist"]} />} />
          <Route path="/votersbyage" element={<PrivateRoute element={<VotersByAge />} allowedRoutes={["/displayvoterlist"]} />} />
          <Route path="/areawiselist" element={<PrivateRoute element={<AreaWiseList />} allowedRoutes={["/displayvoterlist"]} />} />
          <Route path="/voterbycast" element={<PrivateRoute element={<VoterByCast />} allowedRoutes={["/displayvoterlist"]} />} />
          <Route path="/visitedvoters" element={<PrivateRoute element={<VisitedVoters />} allowedRoutes={["/displayvoterlist"]} />} />
          <Route path="/fieldworkerlist" element={<PrivateRoute element={<FieldWorkerList />} allowedRoutes={["/displayvoterlist"]} />} />
          <Route path="/marriedfemalelist" element={<PrivateRoute element={<MarriedFemaleVoters />} allowedRoutes={["/displayvoterlist"]} />} />
          <Route path="/surnamelist" element={<PrivateRoute element={<VotersBySurname />} allowedRoutes={["/displayvoterlist"]} />} />
          <Route path="/deadvoters" element={<PrivateRoute element={<DeadVoters />} allowedRoutes={["/displayvoterlist"]} />} />
          <Route path="/changedaddressvoters" element={<PrivateRoute element={<AddressChangedVoters />} allowedRoutes={["/displayvoterlist"]} />} />
          <Route path="/phonenumberlist" element={<PrivateRoute element={<PhoneNumberList />} allowedRoutes={["/displayvoterlist"]} />} />
          <Route path="/votersfamilylist" element={<PrivateRoute element={<FamilyAddedList />} allowedRoutes={["/displayvoterlist"]} />} />
          <Route path="/confirmedvoterslist" element={<PrivateRoute element={<ConfirmedVotersList />} allowedRoutes={["/displayvoterlist"]} />} />
          <Route path="/pollingarea" element={<PrivateRoute element={<PollingAreaPLacesList />} allowedRoutes={["/displayvoterlist"]} />} />
          <Route path="/birthdatelist" element={<PrivateRoute element={<BirthDateList />} allowedRoutes={["/displayvoterlist"]} />} />
          <Route path="/birthdaylist" element={<PrivateRoute element={<BirthdayList />} allowedRoutes={["/displayvoterlist"]} />} />
          <Route path="/pollingAgent" element={<PrivateRoute element={<PollingAgent />} allowedRoutes={["/displayvoterlist"]} />} />
          <Route path="/candidateconfig" element={<PrivateRoute element={<CandidateConfig />} allowedRoutes={["/displayvoterlist"]} />} />
          <Route path="/graphforcount" element={<PrivateRoute element={<GraphForCount />} allowedRoutes={["/graphForCount"]} />} />
        </Routes>
      </BrowserRouter>
    </AuthProvider>
  );
}

export default App;
