import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Header from "./Header";
import Back from "../Assets/Images/backbtn.svg";
import Home from "../Assets/Images/home_icon.svg";
import axios from "axios";

export default function VoterList() {
  const navigate = useNavigate();
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [middleName, setMiddleName] = useState("");
  const [voterId, setVoterId] = useState("");
  const [, setContactNumber] = useState("");
  const [, setAddressLine1] = useState("");
  const [, setAddressLine2] = useState("");
  const [gender, setGender] = useState("");
  const authtoken = localStorage.getItem("token");

  const handleSubmitClick = () => {
    navigate("/thankupage");
  };
  const handleBackClick = () => {
    navigate("/");
  };

  const handleDashboardClick = () => {
    navigate("/dashboard");
  };

  const onChangeFirstName = (value) => {
    setFirstName(value);
  };

  const onChangeMiddleName = (value) => {
    setMiddleName(value);
  };

  const onChangeLastName = (value) => {
    setLastName(value);
  };

  const onChangeVoterId = (value) => {
    setVoterId(value);
  };

  const onselectGender = (value) => {
    setGender(value);
  };
  const onAddressLine1Change = (value) => {
    setAddressLine1(value);
  };
  const onAddressLine2Change = (value) => {
    setAddressLine2(value);
  };

  const onChangeContact = (value) => {
    setContactNumber(value);
  };

  const onSubmitClick = () => {
    const data = {
      firstName: firstName,
      middleName: middleName,
      lastName: lastName,
      voterId: voterId,
      gender: gender,
    };

    const config = {
      method: "post",
      url: `${process.env.REACT_APP_DATAEXTRACTAPI}/api/DataExtract/addnewvoter`,
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authtoken,
      },
      data: data,
    };
    axios(config)
      .then((response) => {})
      .catch((error) => {
        console.log(error);
      });
  };
  return (
    <>
      <div className="app">
        <Header />
        <div className="row">
          <div className="col-6">
            <img src={Back} alt="Scanner image" className="back-btn-img" onClick={handleBackClick} />
          </div>

          <div className="col-6 text-end">
            <img src={Home} alt="Scanner image" className="home-btn-img" onClick={handleDashboardClick} />
          </div>
        </div>
        <div className="container main-container">
          <div className="name-voter-list-text">Add my name in the voter list</div>
          <div className="">
            <form>
              <div class="mb-3 mt-2">
                <label for="exampleInputEmail1" class="form-label">
                  Please enter your Name
                </label>
                <input
                  type="text"
                  class="form-control"
                  id="exampleInputEmail1"
                  aria-describedby="emailHelp"
                  onChange={(e) => {
                    onChangeFirstName(e.target.value);
                  }}
                />
              </div>
              <div class="mb-3">
                <label for="exampleInputEmail1" class="form-label">
                  Please enter your Father Name
                </label>
                <input
                  type="text"
                  class="form-control"
                  id="exampleInputEmail1"
                  aria-describedby="emailHelp"
                  onChange={(e) => {
                    onChangeMiddleName(e.target.value);
                  }}
                />
              </div>
              <div class="mb-3">
                <label for="exampleInputEmail1" class="form-label">
                  Please enter your Surname
                </label>
                <input
                  type="text"
                  class="form-control"
                  id="exampleInputEmail1"
                  aria-describedby="emailHelp"
                  onChange={(e) => {
                    onChangeLastName(e.target.value);
                  }}
                />
              </div>
              <div class="mb-3">
                <label htmlFor="genderSelect" className="form-label">
                  Gender
                </label>
                <select
                  name="Gender"
                  className="form-control"
                  id="genderSelect"
                  onChange={(e) => {
                    onselectGender(e.target.value);
                  }}
                >
                  <option value="">Select Gender</option>
                  <option value="female">Female</option>
                  <option value="male">Male</option>
                </select>
              </div>
              <div class="mb-3">
                <label for="exampleInputEmail1" class="form-label">
                  Please enter voter ID No.
                </label>
                <input
                  type="ematextil"
                  class="form-control"
                  id="exampleInputEmail1"
                  aria-describedby="emailHelp"
                  onChange={(e) => {
                    onChangeVoterId(e.target.value);
                  }}
                />
              </div>
              <div class="mb-3">
                <label for="exampleInputEmail1" class="form-label">
                  Address line 1
                </label>
                <input
                  type="ematextil"
                  class="form-control"
                  id="exampleInputEmail1"
                  aria-describedby="emailHelp"
                  onChange={(e) => {
                    onAddressLine1Change(e.target.value);
                  }}
                />
              </div>
              <div class="mb-3">
                <label for="exampleInputEmail1" class="form-label">
                  Address line 2
                </label>
                <input
                  type="ematextil"
                  class="form-control"
                  id="exampleInputEmail1"
                  aria-describedby="emailHelp"
                  onChange={(e) => {
                    onAddressLine2Change(e.target.value);
                  }}
                />
              </div>
              <div class="mb-3">
                <label for="exampleInputEmail1" class="form-label">
                  Contact No.
                </label>
                <input
                  type="ematextil"
                  class="form-control"
                  id="exampleInputEmail1"
                  aria-describedby="emailHelp"
                  onChange={(e) => {
                    onChangeContact(e.target.value);
                  }}
                />
              </div>
            </form>
          </div>
        </div>
        <div className="footer">
          <div className="text-end mb-3">
            <button type="button" class="btn btn-primary btn-back" onClick={handleBackClick}>
              Back
            </button>
            <button type="button" class="btn btn-primary confirm-btn btn-back" style={{ marginLeft: "10px" }} onClick={onSubmitClick}>
              Submit
            </button>
          </div>
        </div>
      </div>
    </>
  );
}
