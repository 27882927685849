import home from "../Assets/Images/home_icon.svg";
import { useNavigate } from "react-router-dom";
import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
import { jwtDecode } from "jwt-decode";
import { BottomScrollListener } from "react-bottom-scroll-listener";
import { ThreeDots } from "react-loader-spinner";
import share from "../Assets/Images/sharevoter.svg";
import print from "../Assets/Images/print.svg";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import MarathiFont from "../Utils/MarathiFont";
import MarathiFontBold from "../Utils/MarathiFontBold"; // Bold variant base64
import { Toast } from "primereact/toast";
import searchimg from "../Assets/Images/search.svg";
import { Button } from "primereact/button";
export default function AreaWiseList() {
  const navigate = useNavigate();
  const [totalCount, setTotalCount] = useState("");
  const [pollingAddressList, setPollingAddressList] = useState([]);
  const [boothAddress, setBoothAddress] = useState(null);
  const [isChecked, setIsChecked] = useState(false);
  const [boothAddressList, setBoothAddressList] = useState([]);
  const [pollingAddress, setPollingAddress] = useState("");
  const [candidateName, setCandidateName] = useState("");
  const [pageNumber, setPageNumber] = useState(1);
  const [isApiCallInProgress, setIsApiCallInProgress] = useState(false);
  const [scrollMore, setScrollMore] = useState(true);
  const [votersListLoading, setVotersListLoading] = useState(false);
  const [showVoterInfo, setShowVoterInfo] = useState(false);
  const [voterDetailsList, setVoterDetailsList] = useState([]);
  const [fromPollingAddress, setFromPollingAddress] = useState("");
  const [fromBoothNumber, setfromBoothNumber] = useState("");
  const [isLoading, setIsLoading] = useState(false); // Flag to prevent multiple API calls
  const userId = localStorage.getItem("userId");
  const toast = useRef("");
  const [showAddressPollingStation, setShowAddressPollingStation] = useState([]);
  const url = localStorage.getItem("slugUrl");
  const authtoken = localStorage.getItem("token");

  useEffect(() => {
    if (localStorage.getItem("token") !== null && localStorage.getItem("token") !== "") {
      const token = localStorage.getItem("token");
      const decoded = jwtDecode(token);
      let candidateName = decoded.UserName;
      setCandidateName(candidateName);
      getPollingAddresses(decoded.UserName);
    }
  }, []);
  const getBoothAddresses = (candidateName, boothnumber) => {
    const config = {
      method: "get",
      url: `${process.env.REACT_APP_DATAEXTRACTAPI}/api/Dashboard/getboothaddress?candidateName=${url}&boothNumber=${boothnumber} `,
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authtoken,
      },
    };

    axios(config)
      .then((response) => {
        if (response?.status === 401) {
          const loginURL = sessionStorage.getItem("candidateLoginURL");
          if (loginURL) {
            // Redirect back to the candidate's login page
            window.location.href = loginURL;
          } else {
            // Fallback to a default login page if the URL is not stored
            window.location.href = process.env.REACT_APP_PROD_API_URL;
          }
        }
        let boothAddressList = response.data.response;
        setBoothAddressList(boothAddressList);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {});
  };

  const onDashboardclick = () => {
    navigate("/candidatedashboard");
  };

  const onSelectOfAddressOfPolling = (item) => {
    setPollingAddress(item);
  };

  const onSelectOfAddressBooth = (item) => {
    setBoothAddress(item);
  };

  const onAllCheckboxSelect = (e) => {
    setIsChecked(e.target.checked);
    setPollingAddress("");
    setBoothAddress("");
  };
  const onSearchClick = (pageno, replace) => {
    if (isApiCallInProgress) return;
    if (replace) {
      setVoterDetailsList([]);
      setTotalCount(0);
    }
    setIsApiCallInProgress(true);
    setVotersListLoading(true);

    const data = {
      boothAddress: boothAddress,
      fromBoothNumber: parseInt(fromBoothNumber) || 0,
      fromValue: 0,
      toValue: 0,
      isGetAllValue: isChecked,
      searchPageNumber: pageno,
      searchResultCount: 20,
    };

    const config = {
      method: "post",
      url: `${process.env.REACT_APP_DATAEXTRACTAPI}/api/Dashboard/getvoterinfobyareawise?candidateName=${url}`,
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authtoken,
      },
      data: data,
    };

    axios(config)
      .then((response) => {
        if (response?.status === 401) {
          const loginURL = sessionStorage.getItem("candidateLoginURL");
          if (loginURL) {
            // Redirect back to the candidate's login page
            window.location.href = loginURL;
          } else {
            // Fallback to a default login page if the URL is not stored
            window.location.href = process.env.REACT_APP_PROD_API_URL;
          }
        }
        setVotersListLoading(false);
        if (response.data.response.length > 0) {
          let searchedList = replace ? response.data.response : [...voterDetailsList, ...response.data.response];
          setVoterDetailsList(searchedList);
          setShowAddressPollingStation(response.data.response[0].addressOfPollingStation);
          setTotalCount(response.data.response[0].totalCount);
        }
        setShowVoterInfo(true);
        if (response.data.response.length > 0) {
          setScrollMore(true);
        } else {
          setScrollMore(false);
        }
      })
      .catch((error) => {
        console.log(error);
        setIsApiCallInProgress(false);
        setVotersListLoading(false);
      })
      .finally(() => {
        setIsApiCallInProgress(false);
      });
  };

  const onSelectFromAddressOfPolling = (e) => {
    var fromboothNo = e.target.value;
    setfromBoothNumber(fromboothNo);
    getBoothAddresses(candidateName, fromboothNo);
  };

  const getPollingAddresses = (candidateName) => {
    // Prevent triggering API if already loading
    if (isLoading) return;

    setIsLoading(true); // Set loading to true

    const config = {
      method: "post",
      url: `${process.env.REACT_APP_DATAEXTRACTAPI}/api/Dashboard/GetDropDownValue?candidateName=${url}`,
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authtoken,
      },
    };

    axios(config)
      .then((response) => {
        if (response?.status === 401) {
          const loginURL = sessionStorage.getItem("candidateLoginURL");
          if (loginURL) {
            // Redirect back to the candidate's login page
            window.location.href = loginURL;
          } else {
            // Fallback to a default login page if the URL is not stored
            window.location.href = process.env.REACT_APP_PROD_API_URL;
          }
        }
        let pollingAddressList = response.data.response;
        setPollingAddressList(pollingAddressList);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setIsLoading(false); // Reset loading state
      });
  };

  const onShowmoreClicked = () => {
    if (!isApiCallInProgress && scrollMore) {
      const nextPageNumber = pageNumber + 1;
      setPageNumber(nextPageNumber);
      onSearchClick(nextPageNumber, false);
    }
  };

  const nameTemplate = (rowData) => {
    return (
      <span>
        {rowData.firstName} {rowData.middleName} {rowData.lastName}
        <br />
        {rowData.voterId}
        <br />
        {rowData.gender}/{rowData.age}
      </span>
    );
  };

  // Load the fonts into vfs
  pdfMake.vfs = {
    ...pdfFonts.pdfMake.vfs, // Keep default fonts
    "AnekDevanagari-Regular.ttf": MarathiFont, // Base64 encoded normal font
    "AnekDevanagari-Bold.ttf": MarathiFontBold, // Base64 encoded bold font
  };

  // Set up custom fonts
  const fonts = {
    AnekDevanagari: {
      normal: "AnekDevanagari-Regular.ttf",
      bold: "AnekDevanagari-Bold.ttf", // Define the bold style
    },
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-indexed
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  };

  const generateDocDefinition = () => {
    const tableRows = voterDetailsList?.map((item) => [
      { text: item.slipNumber.toString(), font: "AnekDevanagari", fontSize: 8 },
      { text: `${item.firstName}${item.middleName}  ${item.lastName}`, font: "AnekDevanagari", fontSize: 8 },
      { text: item.voterId.toString(), font: "AnekDevanagari", fontSize: 8 },
      //  { text: item.boothNumber.toString(), font: "AnekDevanagari", fontSize: 8 },
      { text: item.age.toString(), font: "AnekDevanagari", lineHeight: 0.8, fontSize: 7 },
      { text: item.gender.toString(), font: "AnekDevanagari", lineHeight: 0.8, fontSize: 7 },
      { text: item.caste, font: "AnekDevanagari", fontSize: 7 },
      { text: item.familyMemberCount, font: "AnekDevanagari", fontSize: 7 },
      { text: formatDate(item.birthDate), font: "AnekDevanagari", fontSize: 7 },
    ]);

    return {
      content: [
        {
          text: "भागानुसार यादी",
          fontSize: 14,
          bold: true,
          margin: [0, 0, 0, 10],
          font: "AnekDevanagari",
        },
        {
          text: "मतदान केंद्राचे नाव", // Added text below the title
          fontSize: 9,
          bold: true,
          margin: [0, 0, 0, 5],
          font: "AnekDevanagari",
        },
        {
          // Added polling station address below the "मतदान केंद्राचे नाव"
          text: voterDetailsList[0]?.addressOfPollingStation.toString(), // Fetches the address from the first item in the list
          fontSize: 9,
          lineHeight: 0.8,
          margin: [0, 0, 0, 10],
          font: "AnekDevanagari",
        },
        {
          text: "पभाग", // Added text below the title
          fontSize: 9,
          bold: true,
          margin: [0, 0, 0, 5],
          font: "AnekDevanagari",
        },
        {
          // Added polling station address below the "मतदान केंद्राचे नाव"
          text: voterDetailsList[0]?.wordName.toString(), // Fetches the address from the first item in the list
          fontSize: 9,
          lineHeight: 0.8,
          margin: [0, 0, 0, 10],
          font: "AnekDevanagari",
        },
        {
          style: "tableExample",
          table: {
            headerRows: 1,
            widths: [35, 115, 59, 20, 25, 30, 28, 49],
            body: [
              [
                { text: "अनु क्र.", bold: true, font: "AnekDevanagari", fontSize: 9 },
                { text: "नाव", bold: true, font: "AnekDevanagari", fontSize: 9 },
                { text: "व्होटर आयडी", bold: true, font: "AnekDevanagari", fontSize: 9 },
                //    { text: "भाग क्र.", bold: true, font: "AnekDevanagari", fontSize: 9 },
                { text: "वय", bold: true, font: "AnekDevanagari", fontSize: 9 },
                { text: "लिंग", bold: true, font: "AnekDevanagari", fontSize: 9 },
                { text: "जात", bold: true, font: "AnekDevanagari", fontSize: 9 },
                { text: "सदस्य", bold: true, font: "AnekDevanagari", fontSize: 9 },
                { text: "जन्मदिनांक", bold: true, font: "AnekDevanagari", fontSize: 9 },
              ],
              ...tableRows,
            ],
          },
          layout: "lightHorizontalLines",
        },
      ],
      defaultStyle: {
        font: "AnekDevanagari",
      },
    };
  };

  const handlePrint = () => {
    const docDefinition = generateDocDefinition();
    pdfMake.createPdf(docDefinition, null, fonts).print();
  };

  const savevotesData = (values) => {
    let data = JSON.stringify({
      CandaidateId: userId,
      areawiselist: "areawiselist.pdf",
    });

    savevoters(data).then((res) => {
      if (res.data) {
        if (res.data.statusCode === 401) {
          toast.current?.show({
            severity: "error",
            summary: res.data.message,
            life: 3000,
          });
        } else {
          const docDefinition = generateDocDefinition();

          // Generate the PDF as a blob and give it a filename
          pdfMake.createPdf(docDefinition, null, fonts).getBlob(async (blob) => {
            const fileName = "areawiselist.pdf"; // Set the desired filename

            // Call the onFileUpload function to upload the file and check its success
            const uploadSuccess = await onFileUpload(blob, fileName);

            // Call getPoolingbooth only if the upload was successful
            if (uploadSuccess) {
              const poolingboothSuccess = await getvotersData();

              // Call handleShare only if getPoolingbooth was successful
              if (poolingboothSuccess) {
                handleShare();
              }
            }
          });
        }
      } else {
        toast.current?.show({
          severity: "error",
          summary: "Something went wrong, please contact your admin",
          life: 3000,
        });
      }
    });
  };

  const savevoters = async (data) => {
    return axios
      .post(`${process.env.REACT_APP_DATAEXTRACTAPI}/api/Voter/CandidateInfo`, data, {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + authtoken,
        },
      })
      .then((res) => res)
      .catch((err) => err);
  };

  const getvotersData = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_DATAEXTRACTAPI}/api/Voter/GetCandidateInfo?CandaidateId=${userId}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + authtoken,
        },
      });
      if (response?.status === 401) {
        const loginURL = sessionStorage.getItem("candidateLoginURL");
        if (loginURL) {
          // Redirect back to the candidate's login page
          window.location.href = loginURL;
        } else {
          // Fallback to a default login page if the URL is not stored
          window.location.href = process.env.REACT_APP_PROD_API_URL;
        }
      }
      // Assuming the API returns 200 for a successful response
      if (response.status === 200) {
        handleShare(response.data.areawiselist);
        return true; // Return true on successful call
      } else {
        throw new Error("Failed to fetch polling booth data");
      }
    } catch (err) {
      console.error(err); // Logging the error for debugging
      return false; // Return false if the call fails
    }
  };

  const uploadFiles = async (data) => {
    return axios
      .post(`${process.env.REACT_APP_DATAEXTRACTAPI}/api/Voter/UploadCandidateFile`, data, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: "Bearer " + authtoken,
        },
      })
      .then((res) => res)
      .catch((err) => err);
  };

  const onFileUpload = async (fileBlob, fileName) => {
    const formData = new FormData();
    formData.append("VotersByAgeFile", fileBlob, fileName); // Append blob with filename
    formData.append("CandaidateId", userId); // Candidate ID

    try {
      const response = await uploadFiles(formData);
      if (response && response.status === 200) {
        toast.current?.show({
          severity: "success",
          summary: "फाइल यशस्वीरित्या शेअर केली",
          life: 3000,
        });
        return true; // Return true on successful upload
      } else {
        throw new Error("फाइल अपलोड अयशस्वी.");
      }
    } catch (error) {
      console.error("Upload error:", error);
      toast.current?.show({
        severity: "error",
        summary: "फाइल अपलोड अयशस्वी.",
        life: 3000,
      });
      return false; // Return false on failure
    }
  };

  const handleShare = (response) => {
    const uploadedFileURL = response;

    // Generate the WhatsApp link with the file URL
    const message = "Please find the PDF here: ";
    const whatsappURL = `https://wa.me/?text=${encodeURIComponent(message)}%0A${encodeURIComponent(uploadedFileURL)}`;

    // Open WhatsApp Web with the pre-filled message
    window.open(whatsappURL, "_blank");
  };

  return (
    <div className="app main-container-login voter-by-age-page">
      <Toast ref={toast} />
      <div className="header">
        <div className="row mt-2">
          <div className="col-10 d-flex align-items-center">
            <Button
              icon="pi pi-chevron-left"
              severity="secondary"
              onClick={() => {
                onDashboardclick();
              }}
              className="back-button"
            />
            <label htmlFor="Constituency" className="form-label Polling text-light labelDashboard marLeftHeader mt-2">
              भागानुसार यादी
            </label>
          </div>
          <div className="col-2 text-end mt-2">
            <img
              src={home}
              alt="Whatsapp"
              className="home-img float-end"
              onClick={onDashboardclick}
              style={{ marginBottom: "4px", marginLeft: "10px" }}
            />
          </div>
        </div>

        <div className="row mt-3 text-14">
          <div className="row text-14">
            <label className="polling-booth-label whiteFont">मतदान केंद्र</label>
            <input
              autoFocus
              type="number"
              className={`form-control form-select mb-1 text-14 polling-select ${
                fromBoothNumber === "" || fromBoothNumber === null || fromBoothNumber === 0 ? "option-as-placeholder" : ""
              }`}
              placeholder="मतदान केंद्र"
              name="fromBoothNumber"
              value={fromBoothNumber}
              onChange={(e) => onSelectFromAddressOfPolling(e)}
            />
          </div>
          <div className="row text-14 mt-2">
            <label className="polling-booth-label whiteFont">मतदान केंद्रामध्ये समाविष्ट असलेले भाग </label>
            <select
              name="pollingAddress"
              value={boothAddress}
              disabled={isChecked ? true : false}
              className={`form-control form-select input-box mb-1 text-14 border-0 ${
                boothAddress == "" || boothAddress == null ? "option-as-placeholder" : ""
              }`}
              id="pollingAddress"
              onChange={(e) => onSelectOfAddressBooth(e.target.value)}
            >
              <option className="option-as-placeholder" value="">
                क्षेत्र निवडा
              </option>
              {boothAddressList.map((item, index) => (
                <option value={item}>{item}</option>
              ))}
            </select>
          </div>
          <div className="row mt-2">
            <div className="col-4 mt-1">
              <button
                className="btn show-btn w-auto text-15 padMiddle"
                disabled={fromBoothNumber === "" || fromBoothNumber == null}
                onClick={() => onSearchClick(1, true)}
              >
                शोधा
                <img src={searchimg} alt="Search" style={{ marginLeft: "5px" }} />
              </button>
            </div>
            <div className="col-8 text-end">
              <button className="btn w-auto text-15 text-white me-2" onClick={handlePrint}>
                <img src={print} alt="Print" style={{ marginLeft: "5px" }} /> प्रिंट
              </button>
              <button className="btn w-auto text-15 text-white" onClick={savevotesData}>
                <img src={share} alt="Share" style={{ marginLeft: "5px" }} /> शेअर
              </button>
            </div>
          </div>
        </div>

        {showVoterInfo ? (
          <BottomScrollListener onBottom={onShowmoreClicked}>
            {(scrollRef) => (
              <div
                className="mt-2"
                ref={scrollRef}
                style={{ height: "650px", overflow: "auto", padding: "4px 8px 4px 8px", backgroundColor: "#3e3e3e", borderRadius: "10px" }}
              >
                <div className="card voter-list-card birthdayMarginTop">
                  <div className="row ">
                    <div className="col-2 pr-0 total-details-Surname" style={{ paddingLeft: "10px" }}>
                      <span>अनु क्र.</span>
                    </div>
                    <div className="col-4 total-details-Surname" style={{ paddingLeft: "8px" }}>
                      <span className="text-left">नाव</span>
                    </div>
                    <div className="col-4 total-details-Surname">
                      <span className="text-left"> व्होटर आयडी</span>
                    </div>
                    <div className="col-2 ps-0 total-details-Surname">
                      <span> लिंग/वय</span>
                    </div>
                  </div>
                </div>

                {Object.entries(
                  voterDetailsList.reduce((acc, item) => {
                    const key = `${item.boothNumber}-${item.addressOfPollingStation}`;
                    if (!acc[key]) {
                      acc[key] = { boothNumber: item.boothNumber, address: item.addressOfPollingStation, voters: [] };
                    }
                    acc[key].voters.push(item);
                    return acc;
                  }, {})
                ).map(([key, group], index) => (
                  <div key={index}>
                    <div className="row d-flex mx-auto header-cell kendraList">
                      <div className="col-12 ps-0 total-details-SurnameList" style={{ fontSize: "14px" }}>
                        <span className="voter-list-answer text-justify">
                          {group.boothNumber} - {group.address}
                        </span>
                      </div>
                    </div>

                    {/* Render each voter under the corresponding booth */}
                    {group.voters.map((item, number) => (
                      <div
                        className={
                          group.voters.length === number + 1 && votersListLoading === false
                            ? "card mb-2 voter-list-card last-card-bottom birthdayMarginTop"
                            : "card voter-list-card birthdayMarginTop"
                        }
                        key={number}
                      >
                        <div className="row d-flex mx-auto">
                          <div className="col-2 ps-0 pr-0 total-details-Surname">
                            <span className="voter-list-answer text-justify">{item.slipNumber}</span>
                          </div>
                          <div className="col-4 ps-0 total-details-Surname  " style={{ paddingLeft: "8px" }}>
                            <span className="voter-list-answer">
                              {item.lastName} {item.firstName} {item.middleName}
                            </span>
                          </div>
                          <div className="col-4 ps-0 total-details-Surname">
                            <span className="voter-list-answer text-justify">{item.voterId}</span>
                          </div>
                          <div className="col-2 ps-0 total-details-Surname   ">
                            <span className="voter-list-answer text-end">
                              {item.gender === "महिला" ? "म" : item.gender === "पुरुष" ? "पु" : item.gender}/{item.age}
                            </span>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                ))}
              </div>
            )}
          </BottomScrollListener>
        ) : null}

        {votersListLoading && (
          <div className="d-flex justify-content-center">
            <ThreeDots height="30" width="30" radius="9" color="white" ariaLabel="three-dots-loading" visible={true} />
          </div>
        )}
      </div>
    </div>
  );
}
