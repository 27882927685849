import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
import { jwtDecode } from "jwt-decode";
import { BottomScrollListener } from "react-bottom-scroll-listener";
import home from "../Assets/Images/home_icon.svg";
import searchimg from "../Assets/Images/search.svg";
import { useNavigate } from "react-router-dom";
import share from "../Assets/Images/sharevoter.svg";
import print from "../Assets/Images/print.svg";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import MarathiFont from "../Utils/MarathiFont";
import MarathiFontBold from "../Utils/MarathiFontBold";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";

export default function MarriedFemaleVoters() {
  const navigate = useNavigate();
  const [candidateName, setCandidateName] = useState("");
  const [pageNumber, setPageNumber] = useState(1);
  const [isApiCallInProgress, setIsApiCallInProgress] = useState(false);
  const [scrollMore, setScrollMore] = useState(true);
  const [votersListLoading, setVotersListLoading] = useState(false);
  const [showVoterInfo, setShowVoterInfo] = useState(false);
  const [voterDetailsList, setVoterDetailsList] = useState([]);
  const [fromBoothNumber, setFromBoothNumber] = useState("");
  const [, setPollingAddressList] = useState([]);
  const [, setShowAddressPollingStation] = useState([]);
  const userId = localStorage.getItem("userId");
  const toast = useRef("");
  const url = localStorage.getItem("slugUrl");
  const authtoken = localStorage.getItem("token");

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      const decoded = jwtDecode(token);
      const candidateName = decoded.UserName;
      setCandidateName(candidateName);
      getPollingAddresses(candidateName);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getMarriedFemaleList = (pageno, replace, candidateNm) => {
    if (isApiCallInProgress) return;

    if (replace) {
      setVoterDetailsList([]);
    }
    setIsApiCallInProgress(true);
    setVotersListLoading(true);

    const data = {
      boothAddress: "",
      pollingAddress: "",
      fromValue: 0,
      toValue: 0,
      isGetAllValue: false,
      searchPageNumber: pageno,
      searchResultCount: 20,
      MaritalStatus: "विवाहित",
      fromBoothNumber: parseInt(fromBoothNumber) || 1,
    };

    const config = {
      method: "post",
      url: `${process.env.REACT_APP_DATAEXTRACTAPI}/api/Dashboard/getmarriedfemale?candidateName=${url}`,
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authtoken,
      },
      data: data,
    };

    axios(config)
      .then((response) => {
        if (response?.status === 401) {
          const loginURL = sessionStorage.getItem("candidateLoginURL");
          if (loginURL) {
            // Redirect back to the candidate's login page
            window.location.href = loginURL;
          } else {
            // Fallback to a default login page if the URL is not stored
            window.location.href = process.env.REACT_APP_PROD_API_URL;
          }
        }
        setVotersListLoading(false);
        if (response.data.response.length > 0) {
          const searchedList = replace ? response.data.response : [...voterDetailsList, ...response.data.response];
          setVoterDetailsList(searchedList);
          setShowAddressPollingStation(response.data.response[0].addressOfPollingStation);
          setScrollMore(response.data.response.length > 0);
        } else {
          setScrollMore(false);
        }
        setShowVoterInfo(true);
      })
      .catch((error) => {
        setVotersListLoading(false);
      })
      .finally(() => {
        setIsApiCallInProgress(false);
      });
  };

  const onSearchClick = () => {
    getMarriedFemaleList(1, true, candidateName); // `replace` is set to true to reset the list
  };

  const onShowmoreClicked = () => {
    if (!isApiCallInProgress && scrollMore) {
      const nextPageNumber = pageNumber + 1;
      setPageNumber(nextPageNumber);
      getMarriedFemaleList(nextPageNumber, false, candidateName);
    }
  };

  const onDashboardClick = () => {
    navigate("/candidatedashboard");
  };

  const getPollingAddresses = (candidateName) => {
    const config = {
      method: "post",
      url: `${process.env.REACT_APP_DATAEXTRACTAPI}/api/Dashboard/GetDropDownValue?candidateName=${url}`,
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authtoken,
      },
    };

    axios(config)
      .then((response) => {
        if (response?.status === 401) {
          const loginURL = sessionStorage.getItem("candidateLoginURL");
          if (loginURL) {
            // Redirect back to the candidate's login page
            window.location.href = loginURL;
          } else {
            // Fallback to a default login page if the URL is not stored
            window.location.href = process.env.REACT_APP_PROD_API_URL;
          }
        }
        setPollingAddressList(response.data.response);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const onSelectFromAddressOfPolling = (e) => {
    var fromboothNo = e.target.value;
    setFromBoothNumber(fromboothNo);
  };

  // Load the fonts into vfs
  pdfMake.vfs = {
    ...pdfFonts.pdfMake.vfs, // Keep default fonts
    "AnekDevanagari-Regular.ttf": MarathiFont, // Base64 encoded normal font
    "AnekDevanagari-Bold.ttf": MarathiFontBold, // Base64 encoded bold font
  };

  // Set up custom fonts
  const fonts = {
    AnekDevanagari: {
      normal: "AnekDevanagari-Regular.ttf",
      bold: "AnekDevanagari-Bold.ttf", // Define the bold style
    },
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-indexed
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  };

  const generateDocDefinition = () => {
    const tableRows = voterDetailsList?.map((item) => [
      { text: item.slipNumber.toString(), font: "AnekDevanagari", fontSize: 8 },
      { text: `${item.firstName}${item.middleName}  ${item.lastName}`, font: "AnekDevanagari", fontSize: 8 },
      { text: item.voterId.toString(), font: "AnekDevanagari", fontSize: 8 },
      { text: item.boothNumber.toString(), font: "AnekDevanagari", fontSize: 8 },
      { text: item.age.toString(), font: "AnekDevanagari", lineHeight: 0.8, fontSize: 7 },
      { text: item.gender.toString(), font: "AnekDevanagari", lineHeight: 0.8, fontSize: 7 },
      { text: item.caste, font: "AnekDevanagari", fontSize: 7 },
      { text: item.familyMemberCount, font: "AnekDevanagari", fontSize: 7 },
      { text: formatDate(item.birthDate), font: "AnekDevanagari", fontSize: 7 },
    ]);

    return {
      content: [
        {
          text: "लग्न झालेल्या महिला मतदार",
          fontSize: 14,
          bold: true,
          margin: [0, 0, 0, 10],
          font: "AnekDevanagari",
        },
        {
          text: "मतदान केंद्राचे नाव", // Added text below the title
          fontSize: 9,
          bold: true,
          margin: [0, 0, 0, 5],
          font: "AnekDevanagari",
        },
        {
          // Added polling station address below the "मतदान केंद्राचे नाव"
          text: voterDetailsList[0]?.addressOfPollingStation.toString(), // Fetches the address from the first item in the list
          fontSize: 9,
          lineHeight: 0.8,
          margin: [0, 0, 0, 10],
          font: "AnekDevanagari",
        },
        {
          text: "पभाग", // Added text below the title
          fontSize: 9,
          bold: true,
          margin: [0, 0, 0, 5],
          font: "AnekDevanagari",
        },
        {
          // Added polling station address below the "मतदान केंद्राचे नाव"
          text: voterDetailsList[0]?.wordName.toString(), // Fetches the address from the first item in the list
          fontSize: 9,
          lineHeight: 0.8,
          margin: [0, 0, 0, 10],
          font: "AnekDevanagari",
        },
        {
          style: "tableExample",
          table: {
            headerRows: 1,
            widths: [35, 115, 59, 40, 20, 25, 30, 28, 49],
            body: [
              [
                { text: "अनु क्र.", bold: true, font: "AnekDevanagari", fontSize: 9 },
                { text: "नाव", bold: true, font: "AnekDevanagari", fontSize: 9 },
                { text: "व्होटर आयडी", bold: true, font: "AnekDevanagari", fontSize: 9 },
                { text: "भाग क्र.", bold: true, font: "AnekDevanagari", fontSize: 9 },
                { text: "वय", bold: true, font: "AnekDevanagari", fontSize: 9 },
                { text: "लिंग", bold: true, font: "AnekDevanagari", fontSize: 9 },
                { text: "जात", bold: true, font: "AnekDevanagari", fontSize: 9 },
                { text: "सदस्य", bold: true, font: "AnekDevanagari", fontSize: 9 },
                { text: "जन्मदिनांक", bold: true, font: "AnekDevanagari", fontSize: 9 },
              ],
              ...tableRows,
            ],
          },
          layout: "lightHorizontalLines",
        },
      ],
      defaultStyle: {
        font: "AnekDevanagari",
      },
    };
  };

  const handlePrint = () => {
    const docDefinition = generateDocDefinition();
    pdfMake.createPdf(docDefinition, null, fonts).print();
  };

  const savevotesData = (values) => {
    let data = JSON.stringify({
      CandaidateId: userId,
      MarriedFemaleListFile: "MarriedFemaleListFile.pdf",
    });

    savevoters(data).then((res) => {
      if (res.data) {
        if (res.data.statusCode === 401) {
          toast.current?.show({
            severity: "error",
            summary: res.data.message,
            life: 3000,
          });
        } else {
          const docDefinition = generateDocDefinition();

          // Generate the PDF as a blob and give it a filename
          pdfMake.createPdf(docDefinition, null, fonts).getBlob(async (blob) => {
            const fileName = "MarriedFemaleListFile.pdf"; // Set the desired filename

            // Call the onFileUpload function to upload the file and check its success
            const uploadSuccess = await onFileUpload(blob, fileName);

            // Call getPoolingbooth only if the upload was successful
            if (uploadSuccess) {
              const poolingboothSuccess = await getvotersData();

              // Call handleShare only if getPoolingbooth was successful
              if (poolingboothSuccess) {
                handleShare();
              }
            }
          });
        }
      } else {
        toast.current?.show({
          severity: "error",
          summary: "Something went wrong, please contact your admin",
          life: 3000,
        });
      }
    });
  };

  const savevoters = async (data) => {
    return axios
      .post(`${process.env.REACT_APP_DATAEXTRACTAPI}/api/Voter/CandidateInfo`, data, {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + authtoken,
        },
      })
      .then((res) => res)
      .catch((err) => err);
  };

  const getvotersData = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_DATAEXTRACTAPI}/api/Voter/GetCandidateInfo?CandaidateId=${userId}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + authtoken,
        },
      });
      if (response?.status === 401) {
        const loginURL = sessionStorage.getItem("candidateLoginURL");
        if (loginURL) {
          // Redirect back to the candidate's login page
          window.location.href = loginURL;
        } else {
          // Fallback to a default login page if the URL is not stored
          window.location.href = process.env.REACT_APP_PROD_API_URL;
        }
      }
      // Assuming the API returns 200 for a successful response
      if (response.status === 200) {
        handleShare(response.data.marriedFemaleListFile);
        return true; // Return true on successful call
      } else {
        throw new Error("Failed to fetch polling booth data");
      }
    } catch (err) {
      console.error(err);
      return false; // Return false if the call fails
    }
  };

  const uploadFiles = async (data) => {
    return axios
      .post(`${process.env.REACT_APP_DATAEXTRACTAPI}/api/Voter/UploadCandidateFile`, data, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: "Bearer " + authtoken,
        },
      })
      .then((res) => res)
      .catch((err) => err);
  };

  const onFileUpload = async (fileBlob, fileName) => {
    const formData = new FormData();
    formData.append("MarriedFemaleListFile", fileBlob, fileName); // Append blob with filename
    formData.append("CandaidateId", userId); // Candidate ID

    try {
      const response = await uploadFiles(formData);
      if (response && response.status === 200) {
        toast.current?.show({
          severity: "success",
          summary: "फाइल यशस्वीरित्या शेअर केली",
          life: 3000,
        });
        return true; // Return true on successful upload
      } else {
        throw new Error("फाइल अपलोड अयशस्वी.");
      }
    } catch (error) {
      console.error("Upload error:", error);
      toast.current?.show({
        severity: "error",
        summary: "फाइल अपलोड अयशस्वी.",
        life: 3000,
      });
      return false; // Return false on failure
    }
  };

  const handleShare = (response) => {
    const uploadedFileURL = response;

    // Generate the WhatsApp link with the file URL
    const message = "Please find the PDF here: ";
    const whatsappURL = `https://wa.me/?text=${encodeURIComponent(message)}%0A${encodeURIComponent(uploadedFileURL)}`;

    // Open WhatsApp Web with the pre-filled message
    window.open(whatsappURL, "_blank");
  };

  return (
    <div className="app main-container-login">
      <Toast ref={toast} />
      <div className="header">
        <div className="row mt-2">
          <div className="col-10 d-flex align-items-center">
            <Button
              icon="pi pi-chevron-left"
              severity="secondary"
              onClick={() => {
                onDashboardClick();
              }}
              className="back-button"
            />
            <label htmlFor="Constituency" className="form-label Polling text-light labelDashboard marLeftHeader mt-2">
              लग्न झालेल्या महिला मतदार
            </label>
          </div>
          <div className="col-2 text-end mt-2">
            <img
              src={home}
              alt="Dashboard"
              className="home-img float-end"
              onClick={onDashboardClick}
              style={{ marginBottom: "4px", marginLeft: "10px" }}
            />
          </div>
        </div>
        <div className="col-12 text-14">
          <label className="polling-booth-label whiteFont">मतदान केंद्र निवडा</label>
          <input
            autoFocus
            type="number"
            className={`form-control form-select mb-1 text-14 polling-select ${
              fromBoothNumber === "" || fromBoothNumber === null || fromBoothNumber === 0 ? "option-as-placeholder" : ""
            }`}
            placeholder="मतदान केंद्र"
            name="fromBoothNumber"
            value={fromBoothNumber}
            onChange={(e) => onSelectFromAddressOfPolling(e)}
          />
        </div>
        <div className="row mt-2">
          <div className="col-4 mt-1">
            <button
              className="btn show-btn w-auto text-15 padMiddle"
              disabled={fromBoothNumber === "" || fromBoothNumber === null || fromBoothNumber === 0}
              onClick={onSearchClick}
            >
              शोधा
              <img src={searchimg} alt="Search" style={{ marginLeft: "5px" }} />
            </button>
          </div>
          <div className="col-8 text-end">
            <button className="btn w-auto text-15 text-white me-2" onClick={handlePrint}>
              <img src={print} alt="Print" style={{ marginLeft: "5px" }} /> प्रिंट
            </button>
            <button className="btn w-auto text-15 text-white" onClick={null}>
              <img src={share} alt="Share" style={{ marginLeft: "5px" }} onClick={savevotesData} /> शेअर
            </button>
          </div>
        </div>
        {showVoterInfo ? (
          <BottomScrollListener onBottom={onShowmoreClicked}>
            {(scrollRef) => (
              <div className="mt-2" ref={scrollRef} style={{ height: "650px", overflowY: "auto", overflowX: "hidden" }}>
                <div className="card voter-list-card birthdayMarginTop">
                  <div className="row " disabled={voterDetailsList.length === 0}>
                    <div className="col-2 total-details-SurnameList">
                      <span>अनु क्र.</span>
                    </div>
                    <div className="col-4 total-details-Surname">
                      <span className="text-left">नाव</span>
                    </div>
                    <div className="col-3 total-details-Surname">
                      <span> व्होटर आयडी</span>
                    </div>
                    <div className="col-3 ps-0 total-details-Surname">
                      <span> लिंग/वय</span>
                    </div>
                  </div>
                </div>

                {Object.entries(
                  voterDetailsList.reduce((acc, item) => {
                    const key = `${item.boothNumber}-${item.addressOfPollingStation}`;
                    if (!acc[key]) {
                      acc[key] = { boothNumber: item.boothNumber, address: item.addressOfPollingStation, voters: [] };
                    }
                    acc[key].voters.push(item);
                    return acc;
                  }, {})
                ).map(([key, group], index) => (
                  <div key={index}>
                    <div className="row d-flex mx-auto header-cell kendraList">
                      <div className="col-12 ps-0 total-details-SurnameList mt-2" style={{ fontSize: "14px" }}>
                        <span className="voter-list-answer text-justify">
                          {group.boothNumber} - {group.address}
                        </span>
                      </div>
                    </div>

                    {/* Render each voter under the corresponding booth */}
                    {group.voters.map((item, number) => (
                      <div
                        className={
                          group.voters.length === number + 1 && votersListLoading === false
                            ? "card mb-2 voter-list-card last-card-bottom birthdayMarginTop"
                            : "card voter-list-card birthdayMarginTop"
                        }
                        key={number}
                      >
                        <div className="row d-flex mx-auto">
                          <div className="col-2 ps-0 total-details-SurnameList ">
                            <span className="voter-list-answer ">{item.slipNumber}</span>
                          </div>
                          <div className="col-4 ps-0 total-details-SurnameList ">
                            <span className="voter-list-answer">
                              {item.lastName} {item.firstName} {item.middleName}
                            </span>
                          </div>
                          <div className="col-3 ps-0 total-details-SurnameList ">
                            <span className="voter-list-answer">{item.voterId}</span>
                          </div>
                          <div className="col-3 ps-0 total-details-SurnameList  ">
                            <span className="voter-list-answer">
                              {item.gender}/{item.age}
                            </span>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                ))}
              </div>
            )}
          </BottomScrollListener>
        ) : null}
      </div>
    </div>
  );
}
