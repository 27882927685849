import React, { useEffect, useState, useCallback, useRef } from "react";
import axios from "axios";
import { jwtDecode } from "jwt-decode";
import { BottomScrollListener } from "react-bottom-scroll-listener";
import { ThreeDots } from "react-loader-spinner";
import moment from "moment";
import home from "../Assets/Images/home_icon.svg";
import { useNavigate } from "react-router-dom";
import { Button } from "primereact/button";

export default function VisitedVoters() {
  const navigate = useNavigate();
  const [candidateName, setCandidateName] = useState("");
  const [pageNumber, setPageNumber] = useState(1);
  const [isApiCallInProgress, setIsApiCallInProgress] = useState(false);
  const [scrollMore, setScrollMore] = useState(true);
  const [votersListLoading, setVotersListLoading] = useState(false);
  const [showVoterInfo, setShowVoterInfo] = useState(false);
  const [voterDetailsList, setVoterDetailsList] = useState([]);
  const [totalCount, setTotalCount] = useState("");
  const url = localStorage.getItem("slugUrl");
  const authtoken = localStorage.getItem("token");

  useEffect(() => {
    if (localStorage.getItem("token") !== null && localStorage.getItem("token") !== "") {
      const token = localStorage.getItem("token");
      const decoded = jwtDecode(token);
      let candidateName = decoded.UserName;
      setCandidateName(candidateName);
      getVisitedVotersList(1, true, candidateName);
    }
  }, []);
  const getVisitedVotersList = (pageno, replace, candidateNm) => {
    if (isApiCallInProgress) return;
    if (replace) {
      setVoterDetailsList([]);
      setTotalCount(0);
    }
    setIsApiCallInProgress(true);
    setVotersListLoading(true);

    const data = {
      boothAddress: "",
      pollingAddress: "",
      fromValue: 0,
      toValue: 0,
      isGetAllValue: false,
      searchPageNumber: pageno,
      searchResultCount: 20,
    };

    const config = {
      method: "post",
      url: `${process.env.REACT_APP_DATAEXTRACTAPI}/api/Dashboard/getvisitedvoterinfo?candidateName=${url}`,
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authtoken,
      },
      data: data,
    };

    axios(config)
      .then((response) => {
        if (response?.status === 401) {
          const loginURL = sessionStorage.getItem("candidateLoginURL");
          if (loginURL) {
            // Redirect back to the candidate's login page
            window.location.href = loginURL;
          } else {
            // Fallback to a default login page if the URL is not stored
            window.location.href = process.env.REACT_APP_PROD_API_URL;
          }
        }
        setVotersListLoading(false);
        if (response.data.response.length > 0) {
          let searchedList = replace ? response.data.response : [...voterDetailsList, ...response.data.response];
          setVoterDetailsList(searchedList);
          setTotalCount(response.data.response[0].totalCount);
        }
        setShowVoterInfo(true);
        if (response.data.response.length > 0) {
          setScrollMore(true);
        } else {
          setScrollMore(false);
        }
      })
      .catch((error) => {
        console.log(error);
        setIsApiCallInProgress(false);
        setVotersListLoading(false);
      })
      .finally(() => {
        setIsApiCallInProgress(false);
      });
  };
  const onShowmoreClicked = () => {
    if (!isApiCallInProgress && scrollMore) {
      const nextPageNumber = pageNumber + 1;
      setPageNumber(nextPageNumber);
      getVisitedVotersList(nextPageNumber, false, candidateName);
    }
  };
  const onDashboardclick = () => {
    navigate("/candidatedashboard");
  };

  return (
    <div className="app main-container-login">
      <div className="header">
        <div className="row mt-2">
          <div className="col-10 d-flex align-items-center">
            <Button
              icon="pi pi-chevron-left"
              severity="secondary"
              onClick={() => {
                onDashboardclick();
              }}
              className="back-button" // Apply the custom class
            />
            <label htmlFor="Constituency" className="form-label Polling text-light labelDashboard marLeftHeader mt-2">
              भेट झालेले मतदार
            </label>
          </div>
          <div className="col-2 text-end mt-2">
            <img
              src={home}
              alt="Whatsapp"
              className="home-img float-end"
              onClick={onDashboardclick}
              style={{ marginBottom: "4px", marginLeft: "10px" }}
            />
          </div>
        </div>

        {showVoterInfo ? (
          <BottomScrollListener onBottom={onShowmoreClicked}>
            {(scrollRef) => (
              <div className="mt-2" ref={scrollRef} style={{ height: "650px", overflowY: "auto", overflowX: "hidden" }}>
                {voterDetailsList.map((item, number) => (
                  <div
                    className={
                      voterDetailsList.length == number + 1 && votersListLoading == false
                        ? " card mb-2  voter-list-card last-card-bottom"
                        : "card mb-2  voter-list-card"
                    }
                    key={number}
                  >
                    <div className="row d-flex  mx-auto ">
                      <div className="col-6 ps-0 mb-1">
                        <span className="cast-list"> अनु.क्र : &nbsp;{item.slipNumber}</span>
                      </div>
                      <div className="col-6 p-0 mb-1">
                        <span className="cast-list">Voter ID :&nbsp;{item.voterId}</span>
                      </div>
                      <div className="col-6 ps-0 mb-1">
                        <span className="cast-list">नाव</span>
                      </div>
                      <div className="col-6 ps-0 mb-1">
                        <span className="cast-list">
                          {item.lastName} {item.firstName} {item.middleName}
                        </span>
                      </div>

                      <div className="col-6 ps-0 mb-1">
                        <span className="cast-list">घर क्रमांक</span>
                      </div>
                      <div className="col-6 ps-0 mb-1">
                        <span className="cast-list">{item.houseNumber}</span>
                      </div>
                      <div className="col-3 ps-0 mb-1">
                        <span className="cast-list">वय</span>
                      </div>
                      <div className="col-3 ps-0 mb-1 ">
                        <span className="cast-list">{item.age}</span>
                      </div>
                      <div className="col-3 mb-1">
                        <span className="cast-list"> लिंग</span>
                      </div>
                      <div className="col-3 ps-0 mb-1">
                        <span className="cast-list">{item.gender}</span>
                      </div>

                      <div className="col-6 ps-0 pe-0  mb-1">
                        <input type="checkbox" id="familyMaritalStatusSwitch" class="checkbox" checked={item.visited} />
                        <span
                          htmlFor="familyMaritalStatusSwitch"
                          className={`fw-bold voter-list-label  family-marital-toggle toggle toggleMEET  ${
                            item.visited
                              ? "justify-content-start checked-toggle-span toggleON toggleMEET "
                              : "justify-content-end unchecked-toggle-span"
                          }`}
                        >
                          <span className="text-10 px-1">{item.visited == true ? "भेट दिली" : "भेट नाही"}</span>
                        </span>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            )}
          </BottomScrollListener>
        ) : null}

        {votersListLoading ? (
          <div className="d-flex justify-content-center">
            <ThreeDots height="30" width="30" radius="9" color="white" ariaLabel="three-dots-loading" visible={true} />
          </div>
        ) : null}
      </div>
    </div>
  );
}
