import React, { useState, useEffect } from "react";
import { Line } from "react-chartjs-2";
import { Chart, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend } from "chart.js";
import axios from "axios";
import { jwtDecode } from "jwt-decode";
import { useNavigate } from "react-router-dom";
import home from "../Assets/Images/home_icon.svg";
import { ThreeDots } from "react-loader-spinner";
import { Button } from "primereact/button";

// Register required components in Chart.js
Chart.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

const GraphForCount = () => {
  const navigate = useNavigate();
  const [hourlyData, setHourlyData] = useState([]);
  const [hourLabels, setHourLabels] = useState([]);
  const [dailyData, setDailyData] = useState([]);
  const [dayLabels, setDayLabels] = useState([]);
  const [yearlyData, setYearlyData] = useState([]);
  const [yearLabels, setYearLabels] = useState([]);
  const [linkHourlyData, setLinkHourlyData] = useState([]);
  const [linkDailyData, setLinkDailyData] = useState([]);
  const [linkYearlyData, setLinkYearlyData] = useState([]);
  const [loading, setLoading] = useState(true);
  const url = localStorage.getItem("slugUrl");
  // Get today's date, month, and year
  const today = new Date();
  const todayDate = today.toISOString().split("T")[0];
  const currentMonth = today.getMonth() + 1;
  const currentYear = today.getFullYear();
  const authtoken = localStorage.getItem("token");

  // Fetch hourly data for today's date
  const fetchHourlyData = async (candidateName) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_DATAEXTRACTAPI}/api/DataExtract/GetUrlHitCountsForDate?candidateName=${url}`,
        {
          date: todayDate, // This is the request body
        },
        {
          headers: {
            "Content-Type": "application/json", // Content-Type header
            Authorization: "Bearer " + authtoken, // Authorization header with token
          },
        }
      );

      const data = response.data;
      if (data.length > 0) {
        setHourlyData(data.map((d) => d.hitCount));
        setHourLabels(data.map((d) => d.timeUnit));
      }
    } catch (error) {
      console.error("Error fetching hourly data:", error);
    }
  };

  // Fetch daily data for the current month
  const fetchDailyData = async (candidateName) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_DATAEXTRACTAPI}/api/DataExtract/GetUrlHitCountsForMonth?candidateName=${url}`,
        {
          month: currentMonth, // This is the request body
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + authtoken,
          },
        }
      );

      const data = response.data;
      if (data.length > 0) {
        setDailyData(data.map((d) => d.hitCount));
        setDayLabels(data.map((d) => d.timeUnit));
      }
    } catch (error) {
      console.error("Error fetching daily data:", error);
    }
  };

  // Fetch yearly data for the current year
  const fetchYearlyData = async (candidateName) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_DATAEXTRACTAPI}/api/DataExtract/GetUrlHitCountsForYear?candidateName=${url}`,
        {
          year: currentYear, // This is the request body
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + authtoken,
          },
        }
      );

      const data = response.data;
      if (data.length > 0) {
        setYearlyData(data.map((d) => d.hitCount));
        setYearLabels(data.map((d) => d.timeUnit));
      }
    } catch (error) {
      console.error("Error fetching yearly data:", error);
    }
  };

  // Fetch link share data
  const fetchLinkShareData = async (candidateName) => {
    try {
      // Fetch hourly link share data
      const hourlyResponse = await axios.post(
        `${process.env.REACT_APP_DATAEXTRACTAPI}/api/DataExtract/GetLinkShareCountsForDate?candidateName=${url}`,
        { date: todayDate },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + authtoken,
          },
        }
      );
      if (hourlyResponse.data.length > 0) {
        setLinkHourlyData(hourlyResponse.data.map((d) => d.hitCount));
      }

      // Fetch daily link share data
      const dailyResponse = await axios.post(
        `${process.env.REACT_APP_DATAEXTRACTAPI}/api/DataExtract/GetLinkShareCountsForMonth?candidateName=${url}`,
        { month: currentMonth },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + authtoken,
          },
        }
      );
      if (dailyResponse.data.length > 0) {
        setLinkDailyData(dailyResponse.data.map((d) => d.hitCount));
      }

      // Fetch yearly link share data
      const yearlyResponse = await axios.post(
        `${process.env.REACT_APP_DATAEXTRACTAPI}/api/DataExtract/GetLinkShareCountsForYear?candidateName=${url}`,
        { year: currentYear },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + authtoken,
          },
        }
      );
      if (yearlyResponse.data.length > 0) {
        setLinkYearlyData(yearlyResponse.data.map((d) => d.hitCount));
      }
    } catch (error) {
      console.error("Error fetching link share data:", error);
    }
  };

  const onDashboardclick = () => {
    navigate("/candidatedashboard");
  };

  // Fetch all data on component mount
  useEffect(() => {
    const fetchData = async () => {
      const token = localStorage.getItem("token");
      const decoded = jwtDecode(token);
      await fetchHourlyData(decoded.UserName);
      await fetchDailyData(decoded.UserName);
      await fetchYearlyData(decoded.UserName);
      await fetchLinkShareData(decoded.UserName);
      setLoading(false);
    };
    fetchData();
  }, []);

  // Chart options
  const chartOptionsForMonthlyCount = {
    scales: {
      x: {
        ticks: {
          color: "white",
        },
      },
      y: {
        ticks: {
          color: "white",
          stepSize: 100,
          beginAtZero: true,
          callback: function (value) {
            return Number.isInteger(value) ? value : null; // Show only integer ticks
          },
        },
        min: 100,
        max: 1000,
      },
    },
  };

  const chartOptionsForDailyCount = {
    scales: {
      x: {
        ticks: {
          color: "white",
        },
      },
      y: {
        ticks: {
          color: "white",
          stepSize: 10,
          beginAtZero: true,
          callback: function (value) {
            return Number.isInteger(value) ? value : null; // Show only integer ticks
          },
        },
        min: 0,
        max: 100,
      },
    },
  };

  const chartOptionsForYearlyCount = {
    scales: {
      x: {
        ticks: {
          color: "white",
        },
      },
      y: {
        ticks: {
          color: "white",
          stepSize: 1000,
          beginAtZero: true,
          callback: function (value) {
            return Number.isInteger(value) ? value : null; // Show only integer ticks
          },
        },
        min: 1000,
        max: 10000,
      },
    },
  };

  return (
    <div style={{ marginTop: "20px", marginRight: "20px", marginLeft: "20px" }}>
      <div className="row mt-2">
        <div className="col-10 d-flex align-items-center">
          <Button
            icon="pi pi-chevron-left"
            severity="secondary"
            onClick={() => {
              onDashboardclick();
            }}
            className="back-button"
          />
          <label htmlFor="QrScanLink" className="form-label Polling text-light labelDashboard marLeftHeader mt-2">
            क्यू आर स्कॅन/लिंक
          </label>
        </div>
        <div className="col-2 text-end mt-2">
          <img
            src={home}
            alt="Whatsapp"
            className="home-img float-end"
            onClick={onDashboardclick}
            style={{ marginBottom: "4px", marginLeft: "10px" }}
          />
        </div>
      </div>
      <div className="col-2 text-end"></div>
      <div className="row">
        <div className="col-12">
          <div className="row">
            <div className="col-12 col-md-4 mb-2">
              <div style={{ border: "1px solid #ccc", padding: "10px", borderRadius: "8px" }}>
                <h4 style={{ color: "#c8c8c8" }}>आजचे स्कॅन</h4>
                {loading ? (
                  <ThreeDots height="30" width="30" radius="9" color="white" ariaLabel="three-dots-loading" visible={true} />
                ) : (
                  <Line
                    data={{
                      labels: hourLabels,
                      datasets: [
                        {
                          label: "Hourly Hit Count",
                          data: hourlyData,
                          backgroundColor: "rgba(0, 122, 255, 0.3)",
                          borderColor: "#007aff",
                          borderWidth: 1,
                        },
                      ],
                    }}
                    options={chartOptionsForDailyCount}
                  />
                )}
              </div>
            </div>
            <div className="col-12 col-md-4 mb-2">
              <div style={{ border: "1px solid #ccc", padding: "10px", borderRadius: "8px" }}>
                <h4 style={{ color: "#c8c8c8" }}>तारखांनुसार स्कॅन</h4>
                {loading ? (
                  <ThreeDots height="30" width="30" radius="9" color="white" ariaLabel="three-dots-loading" visible={true} />
                ) : (
                  <Line
                    data={{
                      labels: dayLabels,
                      datasets: [
                        {
                          label: "Daily Hit Count",
                          data: dailyData,
                          backgroundColor: "rgba(153, 102, 255, 0.3)",
                          borderColor: "rgba(153, 102, 255, 1)",
                          borderWidth: 1,
                        },
                      ],
                    }}
                    options={chartOptionsForMonthlyCount}
                  />
                )}
              </div>
            </div>
            <div className="col-12 col-md-4 mb-2">
              <div style={{ border: "1px solid #ccc", padding: "10px", borderRadius: "8px" }}>
                <h4 style={{ color: "#c8c8c8" }}>महिन्यानुसार स्कॅन</h4>
                {loading ? (
                  <ThreeDots height="30" width="30" radius="9" color="white" ariaLabel="three-dots-loading" visible={true} />
                ) : (
                  <Line
                    data={{
                      labels: yearLabels,
                      datasets: [
                        {
                          label: "Yearly Hit Count",
                          data: yearlyData,
                          backgroundColor: "rgba(255, 99, 132, 0.3)",
                          borderColor: "rgba(255, 99, 132, 1)",
                          borderWidth: 1,
                        },
                      ],
                    }}
                    options={chartOptionsForYearlyCount}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-12">
          <div className="row">
            <div className="col-12 col-md-4 mb-2">
              <div style={{ border: "1px solid #ccc", padding: "10px", borderRadius: "8px" }}>
                <h4 style={{ color: "#c8c8c8" }}>आजचे शेअर्स</h4>
                {loading ? (
                  <ThreeDots height="30" width="30" radius="9" color="white" ariaLabel="three-dots-loading" visible={true} />
                ) : (
                  <Line
                    data={{
                      labels: hourLabels,
                      datasets: [
                        {
                          label: "Hourly Link Share Count",
                          data: linkHourlyData,
                          backgroundColor: "rgba(0, 122, 255, 0.3)",
                          borderColor: "#007aff",
                          borderWidth: 1,
                        },
                      ],
                    }}
                    options={chartOptionsForDailyCount}
                  />
                )}
              </div>
            </div>
            <div className="col-12 col-md-4 mb-2">
              <div style={{ border: "1px solid #ccc", padding: "10px", borderRadius: "8px" }}>
                <h4 style={{ color: "#c8c8c8" }}>तारखांनुसार शेअर्स</h4>
                {loading ? (
                   <ThreeDots height="30" width="30" radius="9" color="white" ariaLabel="three-dots-loading" visible={true} />
                ) : (
                  <Line
                    data={{
                      labels: dayLabels,
                      datasets: [
                        {
                          label: "Daily Link Share Count",
                          data: linkDailyData,
                          backgroundColor: "rgba(153, 102, 255, 0.3)",
                          borderColor: "rgba(153, 102, 255, 1)",
                          borderWidth: 1,
                        },
                      ],
                    }}
                    options={chartOptionsForMonthlyCount}
                  />
                )}
              </div>
            </div>
            <div className="col-12 col-md-4 mb-2">
              <div style={{ border: "1px solid #ccc", padding: "10px", borderRadius: "8px" }}>
                <h4 style={{ color: "#c8c8c8" }}>महिन्यानुसार शेअर्स</h4>
                {loading ? (
                   <ThreeDots height="30" width="30" radius="9" color="white" ariaLabel="three-dots-loading" visible={true} />
                ) : (
                  <Line
                    data={{
                      labels: yearLabels,
                      datasets: [
                        {
                          label: "Yearly Link Share Count",
                          data: linkYearlyData,
                          backgroundColor: "rgba(255, 99, 132, 0.3)",
                          borderColor: "rgba(255, 99, 132, 1)",
                          borderWidth: 1,
                        },
                      ],
                    }}
                    options={chartOptionsForYearlyCount}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GraphForCount;
