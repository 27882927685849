import React, { useCallback, useEffect, useRef } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useState } from "react";
import axios from "axios";
import muteicon from "../Assets/Images/mute.svg";
import Soundimage from "../Assets/Images/Soundimage.svg";
import btnimg from "../Assets/Images/button.svg";
import Ellipsis from "../Assets/Images/Ellipse.png";
import { Dialog } from "primereact/dialog";
import Namskar from "../Assets/Images/Namskar.svg";
import { jwtDecode } from "jwt-decode";
import _ from "lodash";
import Webcam from "react-webcam";
import WhiteWPImage from "../Assets/Images/white-wp.svg";
import familyicon from "../Assets/Images/Familyicon.svg";
import { BottomScrollListener } from "react-bottom-scroll-listener";
import { ThreeDots } from "react-loader-spinner";
import { Toast } from "primereact/toast";
import { InputSwitch } from "primereact/inputswitch";
import arrow from "../Assets/Images/arrow.svg";
import { TabView, TabPanel } from "primereact/tabview";
import Ivoter from "../Assets/Images/Ivoter.jpeg";

export default function VoterForm(props) {
  const [isFamilyCardOpen, setIsfamilyCardOpen] = useState(false);
  const navigate = useNavigate();
  const webcamRef = useRef(null);
  const [, setFirstName] = useState("");
  const [, setLastName] = useState("");
  const [, setMiddleName] = useState("");
  const [voterId, setVoterId] = useState("");
  const [, setSearchResultList] = useState([]);
  const [searchSuggestionList, setSearchSuggestionList] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");
  const [, setLoading] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState(localStorage.getItem("selectedLanguage") || "Marathi");
  const [isPlaying, setIsPlaying] = useState(false);
  const [visible, setVisible] = useState(false);
  const searchParams = new URLSearchParams(window.location.search);
  const scanned = searchParams.get("scanned");
  const name = searchParams.get("name");
  const [, setWorkerId] = useState(0);
  const [fullName, setFullName] = useState("");
  const [showWebCam, setShowWebCam] = useState(false);
  const [, setIsWebCamOn] = useState(true);
  const [scanLoadingIcon, setScanLoadingIcon] = useState(false);
  const [facingMode, setFacingMode] = useState("environment");
  const [mobileNo, setMobileNo] = useState("");
  const [suggestionListLoading, setSuggestionListLoading] = useState(false);
  const [showEditMode, setShowEditMode] = useState(false);
  const [editVoterId, setEditVoterId] = useState("");
  const [pageNumber, setPageNumber] = useState(1);
  const [scrollMore, setScrollMore] = useState(true);
  const toast = useRef("");
  const [isSharedNumberCardOpen, setIsSharedNumberCardOpen] = useState();
  const [familyList, setFamilyList] = useState([]);
  const [showCardId, setShowCardId] = useState([]);
  const [isConfirmed, setIsConfirmed] = useState(false);
  const [, setWhatsappNumber] = useState("");
  const [primaryFamilyMemberId, setPrimaryFamilyMemberId] = useState(0);
  const [, setWhatsAppError] = useState("");
  const [addingFamilyLoading, setAddingFamilyLoading] = useState(false);
  const [isApiCallInProgress, setIsApiCallInProgress] = useState(false);
  const [showFamilyListLoading, setShowFamilyListLoading] = useState(false);
  const [showFirstLandingPage, setFirstNameLandingPage] = useState(true);
  const inputRef = useRef(null);
  const [candidateData, setCandidateData] = useState(null);
  const [logoLoaded, setLogoLoaded] = useState(false);
  const formattedDate = new Date(candidateData?.codeofconduct).toLocaleDateString("en-US", {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
  });

  const [errorMessageForMobileNo, setErrorMessageForMobileNo] = useState("");
  const [activeIndex, setActiveIndex] = useState(0);
  const audioRef = useRef(null);
  const authtoken = localStorage.getItem("token");

  useEffect(() => {
    if (scanned == "true") {
      localStorage.removeItem("token");
      setVisible(true);
    } else {
      setVisible(false);
      setFirstNameLandingPage(false);
    }
    if (localStorage.getItem("token") !== null && localStorage.getItem("token") !== "") {
      const token = localStorage.getItem("token");
      const decoded = jwtDecode(token);
      const workerId = decoded.Id;
      setWorkerId(workerId);
      onNameSearchSuggestion("", pageNumber, false, "", workerId, "");
    } else {
      setWorkerId(null);
      onNameSearchSuggestion("", pageNumber, false, "", 0, "");
    }
  }, []);

  // Play or pause the audio based on isPlaying state
  useEffect(() => {
    const audio = audioRef.current;

    if (!audio || !candidateData?.audio) return; // Ensure audio element and audio URL exist

    const playSound = () => {
      if (audio) {
        audio.play().catch((error) => {
          console.error("Failed to play audio:", error);
        });
        setIsPlaying(true);
      }
    };

    const pauseSound = () => {
      if (audio) {
        audio.pause();
        setIsPlaying(false);
      }
    };

    // Set audio src only if the audio URL exists
    audio.src = candidateData.audio;

    if (isPlaying) {
      playSound();
    } else {
      pauseSound();
    }

    return () => {
      if (audio) audio.pause(); // Cleanup on component unmount
    };
  }, [isPlaying, candidateData?.audio]);

  const togglePlay = () => {
    setIsPlaying((prevIsPlaying) => !prevIsPlaying);
  };

  const onBackClick = () => {
    window.location.reload();
  };

  const onChangeVoterId = (value) => {
    if (value.length > 10) {
      return;
    }
    const value1 = value.trim();
    setFirstName("");
    setMiddleName("");
    setLastName("");
    setFullName("");
    setSearchSuggestionList([]);
    if (checkVoterIdNumberValidation(value1)) {
      setVoterId(value1);
      onNameSearchSuggestion("", 1, true, value1, 0, "");
      setErrorMessage("");
    } else {
      if (value1 === "") {
        setVoterId(value1);
        onNameSearchSuggestion("", 1, true, "", 0, "");
        setErrorMessage("");
      } else {
        setVoterId(value1);
        setErrorMessage("कृपया वैध मतदार क्रमांक प्रविष्ट करा");
      }
    }
  };

  const onChangeMobileNo = (value) => {
    if (value.length > 10) {
      return;
    }

    const value1 = value.trim();
    setFirstName("");
    setMiddleName("");
    setLastName("");
    setFullName("");
    setSearchSuggestionList([]);
    setVoterId("");
    setErrorMessage("");
    if (checkMobileNumberValidation(value1)) {
      setMobileNo(value1);
      onNameSearchSuggestion("", 1, true, "", 0, value1);
      setErrorMessageForMobileNo("");
    } else {
      if (value1 === "") {
        setMobileNo(value1);
        onNameSearchSuggestion("", 1, true, "", 0, "");
        setErrorMessageForMobileNo("");
      } else {
        setMobileNo(value1);
        setErrorMessageForMobileNo("कृपया वैध मोबाइल नंबर प्रविष्ट करा");
      }
    }
  };

  const onChangeFirstName = (value) => {
    let replaceValue;
    if (value != fullName || fullName == "") {
      replaceValue = true;
    } else {
      replaceValue = false;
    }

    let workerId = 0;
    if (localStorage.getItem("token") !== null && localStorage.getItem("token") !== "") {
      const token = localStorage.getItem("token");
      const decoded = jwtDecode(token);
      workerId = decoded.Id;
    }
    setVoterId("");
    setSearchSuggestionList([]);
    setIsfamilyCardOpen(false);
    setIsConfirmed(false);
    setWhatsAppError("");
    setIsSharedNumberCardOpen(false);
    setIsfamilyCardOpen(false);
    setFullName(value);
    let name = value.split(" ");
    setFirstName(name[0]);
    setMiddleName(name[1]);
    setLastName(name[2]);
    setErrorMessage("");
    setErrorMessageForMobileNo("");
    setMobileNo("");
    setPageNumber(1);
    if (value) {
      debouncedFetchResults(value, 1, workerId, replaceValue);
    } else {
      debouncedFetchResults(value, 1, workerId, replaceValue);
    }
    // }
    return () => {
      debouncedFetchResults.cancel();
    };
  };

  const onAgeNumberChange = (e, number) => {
    const updatedList = [...searchSuggestionList];
    updatedList[number].age = e.target.value;
    setSearchSuggestionList(updatedList);
  };

  const onPhoneNumberChange = (e, number) => {
    const updatedList = [...searchSuggestionList];
    updatedList[number].mobileNo = e.target.value;
    setSearchSuggestionList(updatedList);
  };

  const onselectGender = (e, number) => {
    const updatedList = [...searchSuggestionList];
    updatedList[number].gender = e.target.value;
    setSearchSuggestionList(updatedList);
  };

  const onselectCaste = (e, number) => {
    const updatedList = [...searchSuggestionList];
    updatedList[number].caste = e.target.value;
    setSearchSuggestionList(updatedList);
  };

  const handleWhatsappClick = (item, number) => {
    setShowCardId(number);
    const updateFamily = [];
    updateFamily.push({
      "अनु क्र": item.slipNumber,
      "ओळखपत्र क्र": item.voterId,
      "बूथ क्र": item.boothNumber,
      नाव: item.lastName + " " + item.firstName + " " + item.middleName,
      वय: item.age,
      लिंग: item.gender,
      पत्ता: item.wordName + " " + item.district,
      "मतदार केंद्र": item.addressOfPollingStation,
    });
    familyList.forEach((item) => {
      if (item.isFamilyMemberCheck == true) {
        updateFamily.push({
          "अनु क्र": item.familyInfo.slipNumber,
          "ओळखपत्र क्र": item.familyInfo.voterId,
          "बूथ क्र": item.familyInfo.boothNumber,
          नाव: item.familyInfo.lastName + " " + item.familyInfo.firstName + " " + item.familyInfo.middleName,
          वय: item.familyInfo.age,
          लिंग: item.familyInfo.gender,
          पत्ता: item.familyInfo.wordName + " " + item.familyInfo.district,
          "मतदार केंद्र": item.familyInfo.addressOfPollingStation,
        });
      }
    });

    const messageArray = updateFamily
      .map((item) => {
        const srNo = `अनु क्र: ${item["अनु क्र"]}`;
        const voterIdNumber = `ओळखपत्र क्र: ${item["ओळखपत्र क्र"]}`;
        const boothNumber = `बूथ क्र: ${item["बूथ क्र"]}`;
        const fullName = `नाव: ${item.नाव}`;
        const age = `वय: ${item.वय}`;
        const gender = `लिंग: ${item.लिंग}`;
        const address = `पत्ता: ${item.पत्ता}`;
        const boothName = `मतदार केंद्र: ${item["मतदार केंद्र"]}`;

        return `
${srNo},
${voterIdNumber},
${boothNumber},
${fullName},
${age},
${gender},
${address},
${boothName}
------------------------------------`;
      })
      .filter((item) => item.length > 0);

    const votingDateTime = `\nमतदानाची तारीख आणि वेळ : २४/१०/२०२४ स. ७.३० ते सायं. ५.३०`;

    const combinedMessage = `${messageArray} ${votingDateTime}`;
    const encodedMessage = encodeURIComponent(combinedMessage);
    // if (whatsappNumber != "" && whatsappNumber != null && whatsappNumber.length == 10) {
    window.open(`https://wa.me/?text=${encodedMessage}`, "_self");
    // }
  };

  const onWhatsappNumberChange = (e) => {
    const value = e.target.value;
    const re = /^[+-]?\d*(?:[.,]\d*)?$/;

    const numericValue = value.replace(/\D/g, "");

    if (re.test(numericValue)) {
      if (numericValue.length > 10) {
        return;
      }
      setWhatsappNumber(numericValue);
    }

    if (numericValue !== null && numericValue !== "" && re.test(numericValue)) {
      if (numericValue.length < 10 || numericValue.length > 10) {
        setWhatsAppError("Please enter a valid contact number");
      } else {
        setWhatsAppError("");
      }
    } else {
      setWhatsAppError("");
    }
  };

  const checkVoterIdNumberValidation = (value) => {
    let isValid = true;
    let regex = new RegExp(/^[A-Z]{3}[0-9]{7}$/);
    if (regex.test(value) == true) {
      return (isValid = true);
    } else {
      return (isValid = false);
    }
  };

  const checkMobileNumberValidation = (value) => {
    let isValid = true;
    let regex = new RegExp(/^\d{10}$/);
    if (regex.test(value)) {
      return (isValid = true);
    } else {
      return (isValid = false);
    }
  };

  const handleConfirmClick = (number) => {
    if (number == showCardId) {
      setIsConfirmed(!isConfirmed);
    } else {
      setIsConfirmed(true);
    }
    setShowCardId(number);
    setWhatsAppError("");
    setIsSharedNumberCardOpen(false);
    setIsfamilyCardOpen(false);
  };

  const handleFamilyToggleCard = async (item, number) => {
    setShowCardId(number);
    setShowFamilyListLoading(true);
    const familyMemberIds = await getFamilyMember(item.id);
    onFamilyClick(item, familyMemberIds);
  };

  const sharebuttonToggleCard = (number) => {
    setShowCardId(number);
    setIsSharedNumberCardOpen(!isSharedNumberCardOpen);
    setWhatsappNumber("");
    setTimeout(() => {
      if (inputRef.current) {
        inputRef.current.focus();
      }
    }, 0);
  };

  const debouncedFetchResults = useCallback(
    _.debounce((searchValue, page, workerId, replaceValue) => {
      onNameSearchSuggestion(searchValue, page, replaceValue, "", workerId, "");
    }, 500),
    []
  );

  const onNameSearchSuggestion = (searchValue, page, replace, voterIdNumber, workerId, mobileNumber) => {
    if (isApiCallInProgress) return;

    if (voterIdNumber != "" && voterIdNumber != null) {
      setSearchSuggestionList([]);
    }
    if (mobileNumber != "" && mobileNumber != null) {
      setSearchSuggestionList([]);
    }
    setIsApiCallInProgress(true);

    let VoterName = searchValue && searchValue.trim() ? searchValue.trim().split(" ") : [""];

    setSuggestionListLoading(true);
    const data = {
      firstName: VoterName[0] === null || VoterName[0] === undefined ? "" : VoterName[0],
      middleName: VoterName[1] === null || VoterName[1] === undefined ? "" : VoterName[1],
      lastName: VoterName[2] === null || VoterName[2] === undefined ? "" : VoterName[2],
      workerId: parseInt(workerId),
      searchResultCount: 20,
      searchPageNumber: page,
      voterId: voterIdNumber === null || undefined ? "" : voterIdNumber,
      mobileNo: mobileNumber === null || undefined ? "" : mobileNumber,
    };
    const selectLanguage = localStorage.getItem("selectedLanguage");
    const config = {
      method: "post",
      url: `${process.env.REACT_APP_DATAEXTRACTAPI}/api/DataExtract/searchsuggestions?candidateName=${name == "null" ? "" : name}`,

      data: data,
    };

    axios(config)
      .then((response) => {
        if (response.data.response.length > 0) {
          let searchedList = replace ? response.data.response : [...searchSuggestionList, ...response.data.response];
          setSearchSuggestionList(searchedList);
        }
        if (response.data.response.length > 0) {
          setScrollMore(true);
        } else {
          setScrollMore(false);
        }
        setSuggestionListLoading(false);
      })
      .catch((error) => {
        setSuggestionListLoading(false);
        setIsApiCallInProgress(false);
      })
      .finally(() => {
        setLoading(false);
        setIsApiCallInProgress(false);
      });
  };
  const handleScroll = (e) => {
    const { scrollTop, scrollHeight, clientHeight } = e.target;
    if (scrollHeight - scrollTop <= clientHeight + 50) {
      onShowmoreClicked();
    }
  };
  const onShowmoreClicked = () => {
    let workerId = 0;
    if (localStorage.getItem("token") !== null && localStorage.getItem("token") !== "") {
      const token = localStorage.getItem("token");
      const decoded = jwtDecode(token);
      workerId = decoded.Id;
    }

    if (!isApiCallInProgress && scrollMore) {
      const nextPageNumber = pageNumber + 1;
      setPageNumber(nextPageNumber);
      if (voterId == "") {
        onNameSearchSuggestion(fullName, nextPageNumber, false, "", workerId, "");
      }
    }
  };
  const onChangeSelected = (language) => {
    setFirstNameLandingPage(false);
    setSelectedLanguage(language);
    localStorage.setItem("selectedLanguage", language);
    setVisible(false);
    setIsPlaying(true);
  };

  const onScanIconClick = () => {
    setShowWebCam(true);
  };

  const captureImage = useCallback(() => {
    if (webcamRef.current) {
      const imageSrc = webcamRef.current.getScreenshot();
      stopWebCam();
      scanImage(imageSrc);
    }
  }, [webcamRef]);

  const onBackButtonClick = () => {
    let workerId = 0;
    if (localStorage.getItem("token") !== null && localStorage.getItem("token") !== "") {
      const token = localStorage.getItem("token");
      const decoded = jwtDecode(token);
      workerId = decoded.Id;
    }
    setShowWebCam(false);
    setIsConfirmed(false);
    setShowCardId("");
    setFullName("");
    setVoterId("");
    onNameSearchSuggestion("", 1, true, "", workerId, "");
  };

  const stopWebCam = () => {
    if (webcamRef.current && webcamRef.current.stream) {
      webcamRef.current.stream.getTracks().forEach((track) => track.stop());
      setIsWebCamOn(false);
    }
  };

  const scanImage = (image) => {
    const imageBase64 = image.split(",")[1];
    setScanLoadingIcon(true);
    const data = {
      imageBase64: imageBase64,
      candidateName: name,
      workerId: 0,
    };
    const config = {
      method: "post",
      url: `${process.env.REACT_APP_DATAEXTRACTAPI}/api/DataExtract/scanimage`,

      data: data,
    };

    axios(config)
      .then((response) => {
        const results = response.data.response.map((item) => ({
          boothNumber: item.boothNumber,
          slipNumber: item.slipNumber,
          firstName: item.firstName,
          middleName: item.middleName,
          lastName: item.lastName,
          age: item.age,
          voterId: item.voterId,
          gender: item.gender,
          sectionNoAndName: response.data.sectionNoAndName,
          addressOfPollingStation: response.data.addressOfPollingStation,
        }));
        setSearchResultList(results);

        navigate("/searchresult", {
          state: {
            searchList: results,
            CandidateName: name,
            selectedLanguage: selectedLanguage,
          },
        });
        setScanLoadingIcon(false);
      })
      .catch((error) => {
        setScanLoadingIcon(false);
      })
      .finally(() => {
        setScanLoadingIcon(false);
      });
  };

  const videoConstraints = {
    facingMode: "environment",
  };

  const onEditVoterDetails = (voterId) => {
    setEditVoterId(voterId);
    setShowEditMode(true);
  };

  const onCancelEditVoterDetails = (voterId) => {
    setEditVoterId(voterId);
    setShowEditMode(false);
  };

  const onUpdateVoterDetails = async (item, index) => {
    let isExistsInCandidate = false;
    if (localStorage.getItem("token") !== null && localStorage.getItem("token") !== "") {
      const token = localStorage.getItem("token");
      const decoded = jwtDecode(token);
      isExistsInCandidate = decoded.isExistsInCandidate;
    }
    // setShowEditMode(false);
    const data = {
      id: item.id,
      WordName: item.wordName,
      slipNumber: item.slipNumber,
      boothNumber: item.boothNumber,
      firstName: item.firstName,
      middleName: item.middleName,
      lastName: item.lastName,
      fatherName: "",
      husbandName: "",
      age: item.age,
      gender: item.gender,
      district: item.district,
      taluka: item.taluka,
      town: item.town,
      addressOfPollingStation: item.addressOfPollingStation,
      voterId: item.voterId,
      workerId: 0,
      caste: item.caste,
      mobileNo: item.mobileNo,
      familyMemberCount: item.familyMemberCount,
      houseNumber: item.houseNumber,
      birthDate: item.birthDate,
    };

    const config = {
      method: "post",
      url: `${process.env.REACT_APP_DATAEXTRACTAPI}/api/Voter/updateVoter?isExistsInCandidate=${isExistsInCandidate}`,
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authtoken,
      },
      data: data,
    };

    try {
      const response = await axios(config);
      if (response.data.response == true) {
        toast.current.show({ severity: "success", detail: "Details updated successfully", life: 3000 });
        setEditVoterId(null);
      } else {
        toast.current.show({ severity: "error", detail: " While Details updating", life: 3000 });
      }
    } catch (error) {}
  };

  const onFamilyClick = (selectedPrimaryFamilyMember, familyMemberIds) => {
    setIsfamilyCardOpen(true);
    setFamilyList([]);

    setPrimaryFamilyMemberId(selectedPrimaryFamilyMember.id);
    const data = {
      firstName: selectedPrimaryFamilyMember.firstName,
      middleName: selectedPrimaryFamilyMember.middleName,
      lastName: selectedPrimaryFamilyMember.lastName,
      workerId: 0,
      voterId: selectedPrimaryFamilyMember.voterId,
    };
    const selectLanguage = localStorage.getItem("selectedLanguage");
    const config = {
      method: "post",
      url: `${process.env.REACT_APP_DATAEXTRACTAPI}/api/DataExtract/searchfamilymember?candidateName=${name == "null" ? "" : name}`,
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authtoken,
      },
      data: data,
    };

    axios(config)
      .then((response) => {
        let familyList = [];
        let updatedFamilyMemberList = [];
        response.data.response.forEach((item) => {
          familyList.push({
            familyInfo: item,
            isFamilyMemberCheck: false,
          });
        });
        const idSet = new Set(familyMemberIds);
        updatedFamilyMemberList = familyList.map((item) => {
          if (idSet.has(item.familyInfo.id)) {
            return { ...item, isFamilyMemberCheck: true };
          }
          return item;
        });
        setFamilyList(updatedFamilyMemberList);
        setShowFamilyListLoading(false);
      })
      .catch((error) => {
        setShowFamilyListLoading(false);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const getFamilyMember = async (familyMemberId) => {
    const config = {
      method: "get",
      url: `${process.env.REACT_APP_DATAEXTRACTAPI}/api/DataExtract/getfamilymember?primaryFamilyMemberId=${familyMemberId}`,
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authtoken,
      },
    };

    try {
      const response = await axios(config);
      return response.data.response;
    } catch (error) {}
  };

  const onAddFamilyClick = () => {
    setAddingFamilyLoading(true);
    let selectedFamilyListIds = [];
    familyList.forEach((element) => {
      if (element.isFamilyMemberCheck == true) {
        selectedFamilyListIds.push(element.familyInfo.id);
      }
    });
    const data = {
      primaryFamilyMemberId: primaryFamilyMemberId,
      fmailyMemberId: selectedFamilyListIds,
    };

    const config = {
      method: "post",
      url: `${process.env.REACT_APP_DATAEXTRACTAPI}/api/DataExtract/addfamilymember`,
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authtoken,
      },
      data: data,
    };

    axios(config)
      .then((response) => {
        if (response.data.response == true) {
          toast.current.show({ severity: "success", detail: "Family updated successfully", life: 3000 });
          setEditVoterId(null);
        } else {
          toast.current.show({ severity: "error", detail: "Unknown error  while adding family member", life: 3000 });
        }
        setAddingFamilyLoading(false);
      })
      .catch((error) => {
        setAddingFamilyLoading(false);
      });
  };

  const onFamilyMemberClick = (item, number) => {
    let newFamilyMemberList = { ...familyList };
    newFamilyMemberList = familyList.map((familyMemberInfo, index) => {
      if (familyMemberInfo.familyInfo.id == item.familyInfo.id) {
        if (item.isFamilyMemberCheck == false) {
          return {
            familyInfo: familyMemberInfo.familyInfo,
            isFamilyMemberCheck: true,
          };
        } else {
          return {
            familyInfo: familyMemberInfo.familyInfo,
            isFamilyMemberCheck: false,
          };
        }
      } else {
        return {
          familyInfo: familyMemberInfo.familyInfo,
          isFamilyMemberCheck: familyMemberInfo.isFamilyMemberCheck,
        };
      }
    });
    setFamilyList(newFamilyMemberList);
  };

  const getCandidate = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_DATAEXTRACTAPI}/api/Voter/CandidateHistory?SlughUrl=${name}`);
      setCandidateData(response.data); // Update state with the data
      setLoading(false); // Set loading to false
    } catch (err) {
      setLoading(false); // Set loading to false
    }
  };

  const LogUserHit = async () => {
    try {
      const response = await axios.post(`${process.env.REACT_APP_DATAEXTRACTAPI}/api/DataExtract/LogUserHit?candidateName=${name}`);
      //  setCandidateData(response.data); // Update state with the data
    } catch (err) {
      setLoading(false); // Set loading to false
    }
  };

  useEffect(() => {
    if (name) {
      getCandidate();
      LogUserHit();
    }
  }, [name]);

  const ElectrolMarathiHistoryData = async (data) => {
    return axios
      .post(`${process.env.REACT_APP_DATAEXTRACTAPI}/api/DataExtract/ElectrolMarathiHistory`, data, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((res) => res)
      .catch((err) => err);
  };

  const saveElectrolMarathiHistoryData = (Id) => {
    let data = JSON.stringify({
      id: Id,
    });

    ElectrolMarathiHistoryData(data).then((res) => {
      if (res.data) {
        if (res.data.statusCode === 401) {
          toast.current?.show({
            severity: "error",
            summary: res.data.message,
            life: 3000,
          });
        }
      } else {
        toast.current?.show({
          severity: "error",
          summary: "Something went wrong, please contact your admin",
          life: 3000,
        });
      }
    });
  };

  return (
    <div className="app">
      <Toast ref={toast} />
      {showFirstLandingPage ? (
        <Dialog
          visible={visible}
          style={{ width: "100vw", height: "100vh", background: "#000" }}
          className="popup-lang"
          onHide={() => {
            if (!visible) return;
            setVisible(false);
          }}
        >
          {new Date(formattedDate).setHours(0, 0, 0, 0) >= new Date().setHours(0, 0, 0, 0) ? (
            <div>
              <div style={{ display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column", padding: "10px" }}>
                <img
                  src={Ivoter}
                  alt="Ivoter"
                  style={{ width: "100%", maxWidth: "600px", height: "auto" }}
                  loading="lazy"
                  onError={(e) => {
                    e.target.src = "path_to_placeholder_image";
                  }}
                />
              </div>

              <div style={{ marginTop: "10%", padding: "0 15px", textAlign: "center" }}>
                <div className="digital-lang-text mt-1">
                  डिजिटल मतदार सुविधा सेवांमध्ये <br />
                  आपले स्वागत आहे.
                </div>

                <div className="col-12 ellipse-div" style={{ display: "flex", justifyContent: "center", alignItems: "center", marginTop: "20px" }}>
                  {!logoLoaded && (
                    <img
                      src="path_to_placeholder_image_or_spinner"
                      alt="Loading"
                      style={{ height: "90px" }} // Placeholder image style
                    />
                  )}
                  <img
                    src={candidateData?.logo}
                    alt="Logo"
                    style={{ height: "90px", display: logoLoaded ? "block" : "none" }} // Hide actual image until loaded
                    onLoad={() => setLogoLoaded(true)} // Set logoLoaded to true once image is loaded
                    onError={(e) => {
                      e.target.src = "path_to_error_image"; // Fallback image in case of error
                      setLogoLoaded(true); // Stop showing placeholder even if image fails to load
                    }}
                  />
                  <img
                    src={btnimg}
                    className="show-cursor"
                    onClick={() => onChangeSelected("Marathi")}
                    alt="Change Language"
                    style={{ cursor: "pointer", height: "30px" }}
                    loading="lazy"
                    onError={(e) => {
                      e.target.src = "path_to_placeholder_image";
                    }}
                  />
                </div>
              </div>
            </div>
          ) : (
            <>
              {candidateData ? (
                <>
                  <div className="col-12 col-md-4 mainbannerdiv">
                    <img
                      src={candidateData?.banner}
                      alt=""
                      className="dialog-img"
                      loading="lazy"
                      onError={(e) => {
                        e.target.src = "path_to_placeholder_image";
                      }}
                    />
                  </div>
                  <div style={{ display: "flex", justifyContent: "center" }} className="mt-2">
                    <img
                      src={candidateData?.photo}
                      alt=""
                      className="photowithhand p-1"
                      style={{ maxWidth: "80%" }}
                      loading="lazy"
                      onError={(e) => {
                        e.target.src = "path_to_placeholder_image";
                      }}
                    />
                  </div>
                </>
              ) : null}
              <div className="d-flex" style={{ display: "flex", justifyContent: "center" }}>
                <img
                  src={Namskar}
                  alt="Namskar"
                  className="namskar-img"
                  loading="lazy"
                  onError={(e) => {
                    e.target.src = "path_to_placeholder_image";
                  }}
                />
              </div>

              <div className="digital-lang-text mt-1">
                डिजिटल मतदार सुविधा सेवांमध्ये <br />
                आपले स्वागत आहे.
              </div>
              <span className="text-success mb-2 text-16 panja-text">उमेदवाराच्या चिन्हासमोरील बटन दाबून</span>
              <span className="text-12">
                {candidateData?.firstName} {candidateData?.middleName && candidateData.middleName + " "}
                {candidateData?.lastName}
                <br /> यांना प्रचंड बहुमताने निवडून द्या !
              </span>
              <div className="row text-center mt-1">
                <div className="col-12 ellipse-div">
                  {!logoLoaded && (
                    <img
                      src="path_to_placeholder_image_or_spinner"
                      alt="Loading"
                      style={{ height: "90px" }} // Placeholder image style
                    />
                  )}
                  <img
                    src={candidateData?.logo}
                    alt="Logo"
                    style={{ height: "90px", display: logoLoaded ? "block" : "none" }} // Hide actual image until loaded
                    onLoad={() => setLogoLoaded(true)} // Set logoLoaded to true once image is loaded
                    onError={(e) => {
                      e.target.src = "path_to_error_image"; // Fallback image in case of error
                      setLogoLoaded(true); // Stop showing placeholder even if image fails to load
                    }}
                  />
                  <img
                    src={Ellipsis}
                    alt="Ellipsis"
                    style={{ height: "30px" }}
                    loading="lazy"
                    onError={(e) => {
                      e.target.src = "path_to_placeholder_image";
                    }}
                  />

                  <img
                    src={btnimg}
                    className="show-cursor"
                    onClick={() => onChangeSelected("Marathi")}
                    alt="Change Language"
                    loading="lazy"
                    onError={(e) => {
                      e.target.src = "path_to_placeholder_image";
                    }}
                  />
                </div>

                <div className="col-12">
                  <div className="social-media-icons" style={{ marginTop: "20px" }}>
                    <a href={candidateData?.twitter} target="_blank" rel="noopener noreferrer" className="p-mr-2">
                      <i className="pi pi-twitter" style={{ fontSize: "24px", marginRight: "20px", cursor: "pointer", color: "white" }}></i>
                    </a>
                    <a href={candidateData?.faceBook} target="_blank" rel="noopener noreferrer" className="p-mr-2">
                      <i className="pi pi-facebook" style={{ fontSize: "24px", marginRight: "20px", cursor: "pointer", color: "white" }}></i>
                    </a>
                    <a href={candidateData?.youTube} target="_blank" rel="noopener noreferrer">
                      <i className="pi pi-youtube" style={{ fontSize: "24px", marginRight: "20px", cursor: "pointer", color: "white" }}></i>
                    </a>
                  </div>
                </div>
              </div>
            </>
          )}
        </Dialog>
      ) : (
        <>
          {scanLoadingIcon ? (
            <>
              <div className="row scan-loading-icon">
                <span className="spinner-border spinner-border-md" role="status" aria-hidden="true"></span>
              </div>
            </>
          ) : (
            <>
              {showWebCam ? (
                <>
                  <Webcam
                    ref={webcamRef}
                    screenshotFormat="image/jpeg"
                    className="scan-camera-height"
                    videoConstraints={{
                      ...videoConstraints,
                      facingMode,
                    }}
                  />
                  <div className="row p-2 mt-2 scan-btn-position">
                    <div className="col-12 d-flex justify-content-evenly">
                      <button className="btn btn-normal  continue-btn w-50 me-2" onClick={() => captureImage()}>
                        {" "}
                        Scan{" "}
                      </button>
                      <button className="btn btn-normal cancel-btn w-50" onClick={onBackButtonClick}>
                        {" "}
                        Cancel{" "}
                      </button>
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <div className="header header-dashboard">
                    <div className="row desktop-img">
                      <div className="col-3">
                        <img src={candidateData?.photo} alt="Scanner image" />
                      </div>
                      <div className="col-6 header-middle-title">
                        <div className="smaller-text">२८२ सांगली विधानसभा मतदार सांगतील -- पक्षाचे अधिकृत उमेदवार </div>
                        <div className="large-text">
                          {candidateData?.firstName} {candidateData?.middleName && `${candidateData?.middleName} `}
                          {candidateData?.lastName}
                          <br />
                          प्रचंड बहुमताने निवडून द्या !
                        </div>
                      </div>
                      <div className="col-3 text-end" style={{ padding: "0px" }}>
                        {" "}
                        <img src={candidateData?.logo} alt="Scanner image" style={{ marginRight: "13px" }} />{" "}
                      </div>
                    </div>
                    {/* <img src={candidateData?.banner} alt="Scanner image" className="newdashboardbanner" /> */}
   
               </div>
                  <div className="container-fluid main-container">
                    {/* <div className="row mt-1">
                      <div className="col-8">
                        {" "}
                        <img src={arrow} alt="arrow" onClick={onBackClick} className="arrow-img" color="white" />
                      </div>
                      <div className="col-4 sound-btn text-end">
                        <audio ref={audioRef} preload="auto" />
                        <img
                          src={isPlaying ? Soundimage : muteicon}
                          alt={isPlaying ? "Pause sound" : "Play sound"}
                          onClick={togglePlay}
                          className="audio-img"
                        />
                      </div>
                    </div> */}

                    <div className="row card voter-info-cardEndUser" style={{marginTop:"60px"}}>
                    <div className="row">
                      <div className="col-8">
                        {" "}
                        <img src={arrow} alt="arrow" onClick={onBackClick} className="imgeArrow" color="white" />
                      </div>
                      <div className="col-4 text-end">
                        <audio ref={audioRef} preload="auto" />
                        <img
                          src={isPlaying ? Soundimage : muteicon}
                          alt={isPlaying ? "Pause sound" : "Play sound"}
                          onClick={togglePlay}
                          className="audio-img"
                        />
                      </div>
                    </div>
                      <div className="tabview-description">
                        <span className="text-white text-12" style={{ Lineheight: "20px" }}>
                        मतदार यादीतील नाव शोधण्यासाठी खालील पैकी एक पर्याय निवडा.
                        </span>
                        <TabView className="" activeIndex={activeIndex} onTabChange={(e) => setActiveIndex(e.index)}>
                          <TabPanel header="Voter ID नंबर" className={activeIndex === 0 ? "active-tab mt-4 mb-4" : "mt-4 mb-4"}>
                            <div className="col-12 p-0 mt-2">
                              <input
                                type="text"
                                className="form-control input-VoterFormCSSChange text-center text-white inputBoxCapsule padTopInput"
                                id="voterIdInput"
                                placeholder="Voter ID नंबर टाका"
                                onChange={(e) => {
                                  onChangeVoterId(e.target.value);
                                }}
                                value={voterId}
                              />
                              <span className="text-danger text-12 text-center">{errorMessage}</span>
                            </div>
                          </TabPanel>

                          <TabPanel header="संपूर्ण नाव" className={activeIndex === 1 ? "active-tab mt-4 mb-4" : "mt-4 mb-4"}>
                            <div className="col-12 p-0 mt-2">
                              <input
                                type="text"
                                className="form-control input-VoterFormCSSChange text-center text-white inputBoxCapsule padTopInput"
                                id="nameInput"
                                placeholder="आडनाव नाव मधले नाव"
                                onChange={(e) => {
                                  onChangeFirstName(e.target.value);
                                }}
                                // style={{ fontSize: "20px" }}
                                value={fullName}
                              />
                            </div>
                          </TabPanel>

                          <TabPanel header="मोबाइल" className={activeIndex === 2 ? "active-tab mt-4 mb-4" : "mt-4 mb-4"}>
                            <div className="col-12 p-0 mt-2">
                              <input
                                type="number"
                                className="form-control input-VoterFormCSSChange text-center inputBoxCapsule padTopInput"
                                id="mobileNoInput"
                                placeholder="मोबाईल नंबर"
                                onChange={(e) => {
                                  onChangeMobileNo(e.target.value);
                                }}
                                value={mobileNo}
                                style={{ color: "white" }}
                              />
                              <span className="text-danger text-12 text-center">{errorMessageForMobileNo}</span>
                            </div>
                          </TabPanel>
                        </TabView>
                      </div>
                    </div>
                    <BottomScrollListener onBottom={onShowmoreClicked}>
                      {(scrollRef) => (
                        <div
                          className="mt-2 scrollable-div "
                          ref={scrollRef}
                          style={{ overflowY: "auto", overflowX: "hidden" }}
                          onScroll={handleScroll}
                        >
                          {searchSuggestionList.map((item, number) => (
                            <div
                              className={
                                searchSuggestionList.length == number + 1 && suggestionListLoading == false
                                  ? " card voter-info-card last-card-bottom mt-2"
                                  : "card voter-info-card mt-2"
                              }
                              key={number}
                            >
                              <div className="row d-flex align-items-center w-100 mx-auto font-size-rem ">
                                <div className="col-3">
                                  <label> अनु क्र.</label>
                                </div>
                                <div className="col-3 ps-0">
                                  {" "}
                                  <h6>{item.slipNumber}</h6>
                                </div>
                                <div className="col-6"></div>
                                <div className="col-3">
                                  <label> बूथ क्र.</label>
                                </div>
                                <div className="col-2 ps-0">
                                  <h6>{item.boothNumber}</h6>
                                </div>

                                <div className="col-3 ps-0 text-end">
                                  <label>Voter ID</label>
                                </div>
                                <div className="col-4 ps-0">
                                  <h6>{item.voterId}</h6>
                                </div>

                                <div className="col-3">
                                  <label>नाव</label>
                                </div>
                                <div className="col-9 ps-0 ">
                                  <h6>
                                    {item.lastName} {item.firstName} {item.middleName}
                                  </h6>
                                </div>

                                <div className="col-3">
                                  <label>वय</label>
                                </div>
                                <div className="col-2 ps-0">
                                  {showEditMode && editVoterId === item.voterId ? (
                                    <input
                                      type="number"
                                      className="w-100 edit-input mb-1 "
                                      value={item.age}
                                      name="age"
                                      onChange={(e) => onAgeNumberChange(e, number)}
                                    />
                                  ) : (
                                    <h6>{item.age}</h6>
                                  )}
                                </div>
                                <div className="col-3 text-end">
                                  <label className="">लिंग</label>
                                </div>
                                <div className="col-4 ps-0">
                                  {showEditMode && editVoterId === item.voterId ? (
                                    <select
                                      name="Gender"
                                      value={item.gender || ""}
                                      className="form-control mb-1"
                                      id="Gender"
                                      onChange={(e) => onselectGender(e, number)}
                                    >
                                      <option></option>
                                      <option value="महिला">महिला</option>
                                      <option value="पुरुष">पुरुष</option>
                                    </select>
                                  ) : (
                                    <h6>{item.gender}</h6>
                                  )}
                                </div>
                                <div className="row">
                                  <div className="col-3">
                                    <label>पत्ता</label>
                                  </div>
                                  <div className="col-9 ps-0">
                                    <h6 className="ps-1">
                                      {item.wordName}, {item.district}
                                    </h6>
                                  </div>
                                </div>
                                <div className="row ">
                                  <div className="col-3 ">
                                    <label>मतदार केंद्र</label>
                                  </div>
                                  <div className="col-9 ps-0  ">
                                    <h6 className="ps-1">{item.addressOfPollingStation}</h6>
                                  </div>
                                </div>
                                {showEditMode && editVoterId === item.voterId && (
                                  <>
                                    <div className="col-4">
                                      <label>फोन नंबर</label>
                                    </div>
                                    <div className="col-8 ps-0" style={{ paddingLeft: "0px", paddingRight: "28px" }}>
                                      {showEditMode && editVoterId == item.voterId ? (
                                        <input
                                          type="number"
                                          className="w-100  edit-input mb-1"
                                          value={item.mobileNo}
                                          name="mobileNo"
                                          onChange={(e) => onPhoneNumberChange(e, number)}
                                        />
                                      ) : (
                                        <h6>{item.mobileNo}</h6>
                                      )}
                                    </div>

                                    <div className="col-4">
                                      <label>जात</label>
                                    </div>
                                    <div className="col-8 ps-0" style={{ paddingLeft: "0px", paddingRight: "28px" }}>
                                      {showEditMode && editVoterId === item.voterId ? (
                                        <select
                                          name="Caste"
                                          value={item.caste || ""}
                                          className="form-control"
                                          id="Caste"
                                          onChange={(e) => onselectCaste(e, number)}
                                        >
                                          <option>जात निवडा</option>
                                          <option value="हिंदू">हिंदू</option>
                                          <option value="शीख">शीख</option>
                                          <option value="जैन">जैन</option>
                                          <option value="बौद्ध">बौद्ध</option>
                                          <option value="मुस्लिम">मुस्लिम</option>
                                          <option value="ख्रिश्चन">ख्रिश्चन</option>
                                        </select>
                                      ) : (
                                        <h6>{item.caste}</h6>
                                      )}
                                    </div>
                                  </>
                                )}
                              </div>

                              {isFamilyCardOpen &&
                                number == showCardId &&
                                familyList.map((item, number) => (
                                  <>
                                    <div className="add-family-card">
                                      <div className="row d-flex align-items-center mx-auto w-100 py-2 dashed-border font-size-rem ">
                                        <div className="col-3">
                                          <label> अनु क्र.</label>
                                        </div>
                                        <div className="col-2 ps-0">
                                          {" "}
                                          <h6>{item.familyInfo.slipNumber}</h6>
                                        </div>
                                        <div className="col-4 text-end">
                                          <label className="text-success text-14"> कुटूंब</label>
                                        </div>
                                        <div className="col-2 ">
                                          <InputSwitch
                                            className="family-switch"
                                            checked={item.isFamilyMemberCheck ? true : false}
                                            onChange={() => onFamilyMemberClick(item, number)}
                                          />{" "}
                                        </div>
                                        <div className="col-1 d-flex justify-content-center text-12 "></div>

                                        <div className="col-3">
                                          <label> बूथ क्र.</label>
                                        </div>
                                        <div className="col-2 ps-0">
                                          <h6>{item.familyInfo.boothNumber}</h6>
                                        </div>
                                        <div className="col-3 ps-0 text-end">
                                          <label>Voter ID</label>
                                        </div>
                                        <div className="col-4 ps-0">
                                          <h6>{item.familyInfo.voterId}</h6>
                                        </div>
                                        <div className="col-3">
                                          <label>नाव</label>
                                        </div>
                                        <div className="col-9 ps-0">
                                          <h6>
                                            {item.familyInfo.lastName} {item.familyInfo.firstName} {item.familyInfo.middleName}
                                          </h6>
                                        </div>
                                        <div className="col-3">
                                          <label>वय</label>
                                        </div>
                                        <div className="col-2 ps-0 ">
                                          {showEditMode && editVoterId === number ? (
                                            <input
                                              type="number"
                                              className="w-100"
                                              value={item.familyInfo.age}
                                              name="age"
                                              onChange={(e) => onAgeNumberChange(e, number)}
                                            />
                                          ) : (
                                            <h6>{item.familyInfo.age}</h6>
                                          )}
                                        </div>
                                        <div className="col-3 text-end">
                                          <label>लिंग</label>
                                        </div>
                                        <div className="col-4 ps-0">
                                          {showEditMode && editVoterId === number ? (
                                            <select name="Gender" className="form-control" id="Gender" onChange={(e) => onselectGender(e, number)}>
                                              <option value="Female">स्त्री</option>
                                              <option value="Male">पुरुष</option>
                                            </select>
                                          ) : (
                                            <h6>{item.familyInfo.gender}</h6>
                                          )}
                                        </div>
                                        <div className="row">
                                          <div className="col-3">
                                            <label>पत्ता</label>
                                          </div>
                                          <div className="col-9 ps-0">
                                            <h6 className="ps-1">
                                              {item.familyInfo.wordName}, {item.familyInfo.district}
                                            </h6>
                                          </div>
                                        </div>
                                        <div className="row ">
                                          <div className="col-3">
                                            <label>मतदार केंद्र</label>
                                          </div>
                                          <div className="col-9 ps-0">
                                            <h6 className="ps-1">{item.familyInfo.addressOfPollingStation}</h6>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </>
                                ))}

                              <div className="row mx-auto w-100 " style={{ marginTop: "10px" }}>
                                <div className="col-4 p-1">
                                  <button type="button" className="btn btn-primary confirm-btn d-flex" onClick={() => handleConfirmClick(number)}>
                                    <input
                                      className="form-check-input"
                                      type="checkbox"
                                      value=""
                                      checked={number == showCardId && isConfirmed ? true : false}
                                      id="flexCheckDefault"
                                      style={{ marginBottom: "5px", marginRight: "4px" }}
                                    />
                                    <div style={{ marginTop: "2px" }}>बरोबर आहे</div>
                                  </button>
                                </div>

                                {showFamilyListLoading && number === showCardId ? (
                                  <div className="col-4 d-flex justify-content-center">
                                    <ThreeDots color="#fff" height={30} width={30} />
                                  </div>
                                ) : (
                                  <>
                                    {isFamilyCardOpen && number === showCardId ? (
                                      addingFamilyLoading && number === showCardId ? (
                                        <div className="col-4 d-flex justify-content-center">
                                          <ThreeDots color="#fff" height={30} width={30} />
                                        </div>
                                      ) : familyList.length === 0 ? (
                                        <div className="col-4 p-0 d-flex align-items-center">
                                          <span className=" text-info oops-msg-text text-danger">
                                            <b>कुटुंबातील सदस्य सापडत नाही.</b>
                                          </span>
                                        </div>
                                      ) : (
                                        <div className="col-4 p-1">
                                          <button type="button" className="btn btn-primary confirm-btn d-flex" onClick={onAddFamilyClick}>
                                            <img src={familyicon} alt="Share" className="white-wp-icon" style={{ marginTop: "2px" }} />
                                            कुटुंब जोडा
                                          </button>
                                        </div>
                                      )
                                    ) : (
                                      <div className="col-4 p-1">
                                        <button
                                          type="button"
                                          className="btn btn-primary confirm-btn d-flex"
                                          onClick={() => handleFamilyToggleCard(item, number)}
                                          disabled={!(number === showCardId && isConfirmed)}
                                        >
                                          <img src={familyicon} alt="Share" className="white-wp-icon" style={{ marginTop: "2px" }} />
                                          <div style={{ marginTop: "2px" }}>कुटुंब</div>
                                        </button>
                                      </div>
                                    )}
                                  </>
                                )}

                                <div className="col-4 p-1">
                                  <button
                                    type="button"
                                    className={`btn btn-primary d-flex  ${
                                      number == showCardId && isConfirmed ? " confirm-btn success" : "confirm-btn"
                                    }`}
                                    // onClick={() => sharebuttonToggleCard(number)}
                                    onClick={() => {
                                      saveElectrolMarathiHistoryData(item.id);
                                      handleWhatsappClick(item, number);
                                    }}
                                    disabled={number == showCardId && isConfirmed ? false : true}
                                  >
                                    <img src={WhiteWPImage} alt="Share" className="white-wp-icon" />
                                    <div style={{ marginTop: "2px" }}>शेअर</div>
                                  </button>
                                </div>
                              </div>
                              <div className="row mt-2">
                                <div className="col-12 voting-date-time py-2">
                                  <span className="">मतदानाची तारीख आणि वेळ : २४/१०/२०२४ स. ७.३० ते सायं. ५.३०</span>
                                </div>
                              </div>
                            </div>
                          ))}
                          {suggestionListLoading ? (
                            <div className="d-flex justify-content-center">
                              <ThreeDots color="#fff" height={30} width={30} />
                            </div>
                          ) : null}
                        </div>
                      )}
                    </BottomScrollListener>
                  </div>
                </>
              )}
            </>
          )}
        </>
      )}
    </div>
  );
}
